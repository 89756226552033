import {
  Card,
  Grid,
  Icon,
  TextField,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import React, { useLayoutEffect, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "../../../styles/css/DetailUser.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
// import { addVoucher } from "../../redux/actions/GamesActions";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import GeneralButton from "../../components/buttons/GeneralButton.jsx";
import SelectOfArray from "../../components/select/SelectOfArray";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import {
  getProductList,
  detailVoucherGames,
  updateVoucherGames,
} from "../../redux/actions/GamesActions";
import SelectWithTextAndValue from "../../components/select/SelectWithTextAndValue";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1253FA",
    },
  },
});

const EditGamesVoucher = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [product, setProduct] = useState([]);
  const location = useLocation();
  const redeemState = location.state?.stateRedeem;
  const [inputValue, setInputValue] = useState("");
  const [submit, setSubmit] = useState(false);
  const [gameItems, setGameItems] = useState();
  const [fieldList, setFieldList] = useState();
  const [state, setState] = useState({
    product: "",
    gameCode:"",
    isActive: 1
  });

  const { id } = useParams();

  const getDataProduct = () => {
    getProductList().then((res) => {
      const data = res.data?.data;
      setProduct(
        data.map((item) => ({
          text: item.title,
          value: item.code,
        }))
      );
    });
  };

  const handleChangeInput = (event) => {
    const value = event.target.value;
    setInputValue(value);
    // const lines = value.split("\n");
    // setResultArray(lines);
  };



  const getDetailVoucher = (id) => {
    detailVoucherGames(id)
      .then((res) => {
        const data = res.data?.data;
        setInputValue(data.redeem_code);
        setState((pref) => ({
          ...pref,
          product: data.games_item_code,
          gameCode: data.game_code,
          isActive: data.voucher_status,
        }));
        
      console.log(data);
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire("Oopss!", "Gagal mengambil data voucher", "");
      });
  };

  useEffect(() => {
    getDataProduct();
  }, []);
  useEffect(() => {
    getDetailVoucher(id);
  }, []);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // useLayoutEffect(() => {
  //   console.log("uselayouteffect");
  // }, []);

  const history = useHistory();
  const handleSubmit = () => {
    const data = {
      product: state.product,
      isActive: state.isActive,
      redeem_code: inputValue,
      game_code: state.gameCode,
      item_code: id,
    };
    setSubmit(true);
     updateVoucherGames(data)
       .then(() => {
         setSubmit(false);
         Swal.fire("Success!", "Voucher berhasil disimpan", "success");
         history.push("/games/voucher");
       })
       .catch((err) => {
         let error = err?.response?.data;
         Swal.fire(
           "Oopss!",
           "Data Kategory gagal disimpan",
           "Voucher gagal disimpan"
         );
         setSubmit(false);
       });
  };


  return (
    <div className="analytics m-sm-30 mt-7 text-black">
      <h1 className="fw-600 m-0">Edit Voucher</h1>
      <Card className="mt-5 py-10 px-10">
        <div className="mx-8 px-10 mt-5 mb-8">
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
            className="mb-8"
          >
            <Grid item xs={12} sm={6} md={12}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Product
              </h1>
              <SelectWithTextAndValue
                dataSelect={product}
                state={state}
                setState={setState}
                size="small"
                width="100%"
                name="product"
                menuItemFontSize="text-14"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={12}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Redeem Code
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                minRows={4}
                className="w-full"
                value={inputValue}
                name="redeem_code"
                placeholder="Masukan Kode disini"
                variant="outlined"
                onChange={handleChangeInput}
                multiline
                maxRows={5}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={12}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Product
              </h1>
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    size="medium"
                    style={{ fontWeight: 700 }}
                  />
                )}
                onChange={handleChange}
                value={state.isActive}
                InputProps={{
                  style: {
                    borderRadius: 5,
                  },
                }}
                size={"md"}
                className="bg-white w-full"
                variant="outlined"
                name="isActive"
              >
                <MenuItem key={1} value={1} text={"Active"}>
                  Active
                </MenuItem>
                <MenuItem key={2} value={0} text={"NonActive"}>
                  Sold
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            className="text-white w-full radius-6 text-capitalize py-2"
            disableElevation
            disabled={submit}
          >
            {submit ? (
              <CircularProgress color="secondary" size={25} />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default EditGamesVoucher;
