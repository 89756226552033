import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Swal from "sweetalert2";
import { resendKodeVoucher } from "../../../redux/actions/Transaction/TransactionActions";

const ModalResendEmail = ({ handleClose, open, data }) => {
    const [submit, setSubmit] = useState(false);
    const [params, setParams] = useState({
        email: ""
    });
    const handleChange = ({ target: { value, name } }) => {
        setParams((pref) => ({
            ...pref,
            [name]: value,
        }));
    };
    const handleFormSubmit = () => {
        setSubmit(true);
        const newParams = {
            ...data,
            email: params.email,
        }
        console.log('body', newParams);
        resendKodeVoucher(newParams).then((res) => {
            const success = res.data.data.success
            if(success){
                Swal.fire(
                    "Berhasil",
                    "Resend code voucher berhasil",
                    "success"
                    )
                } else {
                    Swal.fire(
                    "Gagal",
                    "Resend code voucher gagal",
                    "error"
                )
            }
            setSubmit(false);
            handleClose()
        });
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
            <DialogTitle>Masukan Email Pengiriman Voucher:</DialogTitle>
            <DialogContent className="pb-6">
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <ValidatorForm onSubmit={handleFormSubmit} className="w-full">
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="name" className="mb-4">
                                        Email <span className="text-danger">*</span>
                                    </InputLabel>
                                    <TextValidator
                                        id="email"
                                        placeholder="Input Email"
                                        className="w-full"
                                        variant="outlined"
                                        onChange={handleChange}
                                        type="email"
                                        name="email"
                                        value={params.email || ""}
                                        validators={["required"]}
                                        errorMessages={["Masukkan email terlebih dahulu"]}
                                    />
                                </Grid>
                                <Grid item xs={12} className="mt-4">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="text-white w-full radius-6 text-capitalize py-2"
                                        disableElevation
                                        disabled={submit}
                                    >
                                        {submit ? (
                                            <CircularProgress color="secondary" size={25} />
                                        ) : (
                                            "Kirim"
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ModalResendEmail;
