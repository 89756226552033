import { GET_API_DOCS, GET_API_DOCS_PARAMS, GET_API_DOCS_RESPONSES, GET_DETAIL_API_DOCS, GET_DETAIL_API_DOCS_PARAMS, GET_DETAIL_API_DOCS_RESPONSES } from '../../constant.js';

const initialState = {
    dataApiDocs: [],
    dataApiDocsParams: [],
    dataApiDocsResponses: [],
    detailApiDocs: {},
    detailApiDocsParams: {},
    detailApiDocsResponses: {},
};

const ApiDocsReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_API_DOCS: {
            return {
                ...state,
                dataApiDocs: action?.payload,
            };
        }

        case GET_API_DOCS_PARAMS: {
            return {
                ...state,
                dataApiDocsParams: action?.payload,
            };
        }

        case GET_API_DOCS_RESPONSES: {
            return {
                ...state,
                dataApiDocsResponses: action?.payload,
            };
        }

        case GET_DETAIL_API_DOCS: {
            return {
                ...state,
                detailApiDocs: action?.payload,
            };
        }

        case GET_DETAIL_API_DOCS_PARAMS: {
            return {
                ...state,
                detailApiDocsParams: action?.payload,
            };
        }

        case GET_DETAIL_API_DOCS_RESPONSES: {
            return {
                ...state,
                detailApiDocsResponses: action?.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export default ApiDocsReducer;
