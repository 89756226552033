export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const GET_ALL_SALDO = "GET_ALL_SALDO";
export const LOADING_DASHBOARD = "LOADING_DASHBOARD";
export const GET_CHART_CHART_PIE = "GET_CHART_CHART_PIE";
export const GET_CHART_CHART_LINE = "GET_CHART_CHART_LINE";
export const SET_LOADING_CHART_PIE = "SET_LOADING_CHART_PIE";
export const SET_LOADING_CHART_LINE = "SET_LOADING_CHART_LINE";

export const GET_ADMIN = "GET_ADMIN";
export const SET_LOADING_ADMIN = "SET_LOADING_ADMIN";
export const SET_PAGINATION_ADMIN = "SET_PAGINATION_ADMIN";

export const GET_PROVINSI = "GET_PROVINSI";
export const SET_LOADING_PROVINSI = "SET_LOADING_PROVINSI";
export const SET_PAGINATION_PROVINSI = "SET_PAGINATION_PROVINSI";

export const GET_KOTA = "GET_KOTA";
export const SET_LOADING_KOTA = "SET_LOADING_KOTA";
export const SET_PAGINATION_KOTA = "SET_PAGINATION_KOTA";
export const GET_UNREAD_DATA = "GET_UNREAD_DATA";

export const GET_KATEGORI_MANAJEMEN = "GET_KATEGORI_MANAJEMEN";
export const SET_LOADING_KATEGORI_MANAJEMEN = "SET_LOADING_KATEGORI_MANAJEMEN";
export const SET_PAGINATION_KATEGORI_MANAJEMEN =
  "SET_PAGINATION_KATEGORI_MANAJEMEN";

export const GET_DATA_MANAJEMEN = "GET_DATA_MANAJEMEN";
export const SET_LOADING_DATA_MANAJEMEN = "SET_LOADING_DATA_MANAJEMEN";
export const SET_PAGINATION_DATA_MANAJEMEN = "SET_PAGINATION_DATA_MANAJEMEN";

export const GET_SERTIFIKAT = "GET_SERTIFIKAT";
export const SET_LOADING_SERTIFIKAT = "SET_LOADING_SERTIFIKAT";
export const SET_PAGINATION_SERTIFIKAT = "SET_PAGINATION_SERTIFIKAT";

export const GET_ANAK_PERUSAHAAN = "GET_ANAK_PERUSAHAAN";
export const SET_LOADING_ANAK_PERUSAHAAN = "SET_LOADING_ANAK_PERUSAHAAN";
export const SET_PAGINATION_ANAK_PERUSAHAAN = "SET_PAGINATION_ANAK_PERUSAHAAN";

export const GET_LAPORAN_TAHUNAN = "GET_LAPORAN_TAHUNAN";
export const SET_LOADING_LAPORAN_TAHUNAN = "SET_LOADING_LAPORAN_TAHUNAN";
export const SET_PAGINATION_LAPORAN_TAHUNAN = "SET_PAGINATION_LAPORAN_TAHUNAN";

export const GET_LAPORAN_KEUANGAN = "GET_LAPORAN_KEUANGAN";
export const SET_LOADING_LAPORAN_KEUANGAN = "SET_LOADING_LAPORAN_KEUANGAN";
export const SET_PAGINATION_LAPORAN_KEUANGAN =
  "SET_PAGINATION_LAPORAN_KEUANGAN";

export const GET_KATEGORI_KOMITE = "GET_KATEGORI_KOMITE";
export const SET_LOADING_KATEGORI_KOMITE = "SET_LOADING_KATEGORI_KOMITE";
export const SET_PAGINATION_KATEGORI_KOMITE = "SET_PAGINATION_KATEGORI_KOMITE";

export const GET_DATA_KOMITE = "GET_DATA_KOMITE";
export const SET_LOADING_DATA_KOMITE = "SET_LOADING_DATA_KOMITE";
export const SET_PAGINATION_DATA_KOMITE = "SET_PAGINATION_DATA_KOMITE";

export const GET_E_MAGAZINE = "GET_E_MAGAZINE";
export const SET_LOADING_E_MAGAZINE = "SET_LOADING_E_MAGAZINE";
export const SET_PAGINATION_E_MAGAZINE = "SET_PAGINATION_E_MAGAZINE";

export const GET_KATEGORI_MEDIA = "GET_KATEGORI_MEDIA";
export const SET_LOADING_KATEGORI_MEDIA = "SET_LOADING_KATEGORI_MEDIA";
export const SET_PAGINATION_KATEGORI_MEDIA = "SET_PAGINATION_KATEGORI_MEDIA";

export const GET_DATA_MEDIA = "GET_DATA_MEDIA";
export const SET_LOADING_DATA_MEDIA = "SET_LOADING_DATA_MEDIA";
export const SET_PAGINATION_DATA_MEDIA = "SET_PAGINATION_DATA_MEDIA";

export const GET_CSR = "GET_CSR";
export const SET_LOADING_CSR = "SET_LOADING_CSR";
export const SET_PAGINATION_CSR = "SET_PAGINATION_CSR";

export const GET_SHE = "GET_SHE";
export const SET_LOADING_SHE = "SET_LOADING_SHE";
export const SET_PAGINATION_SHE = "SET_PAGINATION_SHE";

export const GET_HERO_SECTION = "GET_HERO_SECTION";
export const SET_LOADING_HERO_SECTION = "SET_LOADING_HERO_SECTION";
export const SET_PAGINATION_HERO_SECTION = "SET_PAGINATION_HERO_SECTION";

export const GET_GLOBAL_DATA = "GET_GLOBAL_DATA";
export const SET_LOADING_GLOBAL = "SET_LOADING_GLOBAL";
export const SET_PAGINATION_GLOBAL = "SET_PAGINATION_GLOBAL";

export const GET_MILESTONE = "GET_MILESTONE";
export const SET_LOADING_MILESTONE = "SET_LOADING_MILESTONE";
export const SET_PAGINATION_MILESTONE = "SET_PAGINATION_MILESTONE";

export const GET_TIPE_ASET = "GET_TIPE_ASET";
export const SET_LOADING_TIPE_ASET = "SET_LOADING_TIPE_ASET";
export const SET_PAGINATION_TIPE_ASET = "SET_PAGINATION_TIPE_ASET";

export const GET_DATA_ASET = "GET_DATA_ASET";
export const SET_LOADING_DATA_ASET = "SET_LOADING_DATA_ASET";
export const SET_PAGINATION_DATA_ASET = "SET_PAGINATION_DATA_ASET";

export const GET_DEPARTEMENT_KARIR = "GET_DEPARTEMENT_KARIR";
export const SET_LOADING_DEPARTEMENT_KARIR = "SET_LOADING_DEPARTEMENT_KARIR";
export const SET_PAGINATION_DEPARTEMENT_KARIR =
  "SET_PAGINATION_DEPARTEMENT_KARIR";

export const GET_DATA_KARIR = "GET_DATA_KARIR";
export const SET_LOADING_DATA_KARIR = "SET_LOADING_DATA_KARIR";
export const SET_PAGINATION_DATA_KARIR = "SET_PAGINATION_DATA_KARIR";

export const GET_PROMOSI = "GET_PROMOSI";
export const SET_LOADING_PROMOSI = "SET_LOADING_PROMOSI";
export const SET_PAGINATION_PROMOSI = "SET_PAGINATION_PROMOSI";

export const GET_FILE_GCG = "GET_FILE_GCG";
export const SET_LOADING_FILE_GCG = "SET_LOADING_FILE_GCG";
export const SET_PAGINATION_FILE_GCG = "SET_PAGINATION_FILE_GCG";

export const GET_TIPE_PROP_DEV = "GET_TIPE_PROP_DEV";
export const SET_LOADING_TIPE_PROP_DEV = "SET_LOADING_TIPE_PROP_DEV";
export const SET_PAGINATION_TIPE_PROP_DEV = "SET_PAGINATION_TIPE_PROP_DEV";

export const GET_DATA_PROP_DEV = "GET_DATA_PROP_DEV";
export const SET_LOADING_DATA_PROP_DEV = "SET_LOADING_DATA_PROP_DEV";
export const SET_PAGINATION_DATA_PROP_DEV = "SET_PAGINATION_DATA_PROP_DEV";

export const GET_HOTEL = "GET_HOTEL";
export const SET_LOADING_HOTEL = "SET_LOADING_HOTEL";
export const SET_PAGINATION_HOTEL = "SET_PAGINATION_HOTEL";

export const GET_PORTFOLIO = "GET_PORTFOLIO";
export const SET_LOADING_PORTFOLIO = "SET_LOADING_PORTFOLIO";
export const SET_PAGINATION_PORTFOLIO = "SET_PAGINATION_PORTFOLIO";

export const GET_INQUIRY = "GET_INQUIRY";
export const SET_LOADING_INQUIRY = "SET_LOADING_INQUIRY";
export const SET_PAGINATION_INQUIRY = "SET_PAGINATION_INQUIRY";

export const GET_MITRA = "GET_MITRA";
export const SET_LOADING_MITRA = "SET_LOADING_MITRA";
export const SET_PAGINATION_MITRA = "SET_PAGINATION_MITRA";

export const GET_SLIDERS = "GET_SLIDERS";

export const GET_PAYMENT_GATEWAY = "GET_PAYMENT_GATEWAY";
export const GET_DETAIL_PAYMENT_GATEWAY = "GET_DETAIL_PAYMENT_GATEWAY";

export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";
export const GET_DETAIL_PAYMENT_METHOD = "GET_DETAIL_PAYMENT_METHOD";

export const GET_GENERAL_INFO = "GET_GENERAL_INFO";

export const GET_FAQ = "GET_FAQ";

export const GET_RIWAYAT_PEMBELIAN = "GET_RIWAYAT_PEMBELIAN";
export const GET_TOTAL_PEMBELIAN = "GET_TOTAL_PEMBELIAN";
export const GET_DETAIL_PEMBELIAN = "GET_DETAIL_PEMBELIAN";

export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_RIWAYAT_TOPUP = "GET_RIWAYAT_TOPUP";
export const GET_TOTAL_TOPUP = "GET_TOTAL_TOPUP";
export const GET_DETAIL_TOPUP = "GET_DETAIL_TOPUP";
export const GET_USERS_TOPUP = "GET_USERS_TOPUP";
export const GET_DETAIL_USER_TOPUP = "GET_DETAIL_USER_TOPUP";
export const SET_USER_SALDO = "SET_USER_SALDO";

export const GET_GRAFIK_PENDAFTARAN = "GET_GRAFIK_PENDAFTARAN";
export const GET_GRAFIK_PENDAPATAN = "GET_GRAFIK_PENDAPATAN";
export const GET_GRAFIK_PENJUALAN = "GET_GRAFIK_PENJUALAN";

export const GET_SNK = "GET_SNK";

export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";

export const GET_PROMO = "GET_PROMO";
export const SET_LOADING_PROMO = "SET_LOADING_PROMO";
export const SET_PAGINATION_PROMO = "SET_PAGINATION_PROMO";

export const GET_CRUD_ADMIN = "GET_CRUD_ADMIN";
export const SET_LOADING_CRUD_ADMIN = "SET_LOADING_CRUD_ADMIN";
export const SET_PAGINATION_CRUD_ADMIN = "SET_PAGINATION_CRUD_ADMIN";

export const GET_DATA_ORDER = "GET_DATA_ORDER";
export const GET_DATA_LOG = "GET_DATA_LOG";

export const GET_API_DOCS = "GET_API_DOCS";
export const GET_DETAIL_API_DOCS = "GET_DETAIL_API_DOCS";
export const GET_API_DOCS_PARAMS = "GET_API_DOCS_PARAMS";
export const GET_DETAIL_API_DOCS_PARAMS = "GET_DETAIL_API_DOCS_PARAMS";
export const GET_API_DOCS_RESPONSES = "GET_API_DOCS_RESPONSES";
export const GET_DETAIL_API_DOCS_RESPONSES = "GET_DETAIL_API_DOCS_RESPONSES";
