import { authRoles } from "../../auth/authRoles";
import DataLog from "./DataLog";

const DataLogRoutes = [
  {
    path: "/data_log",
    component: DataLog,
    auth: authRoles.adm,
    exact: true,
  }
];

export default DataLogRoutes;
