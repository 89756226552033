import { Button, Icon, IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React from "react";
import { Card } from "react-bootstrap";

const CardSliders = ({ data, title = "", handleAdd, handleEdit, handleDelete }) => {
  console.log('dataa',data);
  return !data ? (
    <>
      <h5 className="fw-500">{title}</h5>
      <Card className="bg-blue-gray border-radius-5 border-blue-1 d-flex justify-center items-center min-height-200 h-auto">
        <Button onClick={() => handleAdd()}>
          <div className="d-flex justify-center items-center border-radius-circle bg-primary h-45 w-45 btn-hover-circle">
            <Icon className="text-white fw-700" fontSize="large">
              add-icon
            </Icon>
          </div>
        </Button>
      </Card>
    </>
  ) : (
    <>
      <h5 className="fw-500">{title}</h5>
      <Card
        className="border-radius-5 border-blue-1 min-height-200 h-auto"
        style={{
          background:
            `url(${data?.image})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="d-flex justify-content-center m-auto">
          <IconButton
            className="text-danger bg-light border-radius-circle mr-1"
            onClick={() => handleDelete(data.id)}
          >
            <Delete color="error" fontSize="medium" />
          </IconButton>
          <IconButton
            className="text-danger bg-light border-radius-circle ml-1"
            onClick={() => handleEdit(data)}
          >
            <Edit color="primary" fontSize="medium" />
          </IconButton>
        </div>
      </Card>
    </>
  );
};

export default CardSliders;
