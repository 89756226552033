import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Card, Icon } from "@material-ui/core";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getDetailApiDocs } from "../../../redux/actions/Settings/ApiDocs";

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import FileCopy from '@material-ui/icons/FileCopy';
import Divider from '@material-ui/core/Divider';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

const DetailApiDocumentation = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch()
    const locationState = location.state;
    const { detailApiDocs } = useSelector((state) => state.apiDocs);

    const [responseName, setResponseName] = useState(null);
    const [copyStatus, setCopyStatus] = useState(null);

    const [state, setState] = useState({
        route_name: locationState.route_name || "",
        method: locationState.method || "",
        url: locationState.url || ""
    });
    const id = locationState.api_docs_code || ""

    const getData = () => {
        dispatch(getDetailApiDocs(id))
    }

    useEffect(() => { getData() }, [dispatch])

    console.log('itemm', locationState)
    console.log('detail', detailApiDocs)

    const copyResponse = (response, resName) => {
        try {
            navigator.clipboard.writeText(JSON.stringify(response, null, 2));
            setCopyStatus("Berhasil")
            setResponseName(resName)
            setTimeout(() => {
                setCopyStatus(null)
                setResponseName(null)
            }, 1000)
        } catch (error) {
            if (copyStatus !== "Berhasil") {
                setCopyStatus("Gagal")
                setResponseName(resName)
                setTimeout(() => {
                    setCopyStatus(null)
                    setResponseName(null)
                }, 1000)
            }
        }
    };

    return (
        <div className="m-sm-30">
            <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                className="d-flex items-center"
            >
                <Grid item xs={12} sm>
                    <h2 className="fw-600 text-25 text-black my-auto">
                        Detail API
                    </h2>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm
                    className="d-flex"
                    style={{ justifyContent: "flex-end" }}
                >
                    <Link to={{
                        pathname: "/settings/apidocs",
                        state: locationState,
                    }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className="px-5 py-2 border-2 text-primary fw-500"
                        >
                            <span className="">Kembali</span>
                        </Button>
                    </Link>
                </Grid>
            </Grid>
            <div className="my-8">
                <Card className="py-8 px-5">
                    {/* Menu */}
                    <div className="px-10 mx-5">
                        <Grid
                            container
                            className="mt-3"
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-end"
                        >
                            <Grid item xs={12} sm={12}>
                                <h3 className="fw-500 text-15 text-black">Nama Route</h3>
                                <TextField
                                    InputProps={{
                                        style: {
                                            borderRadius: 5,
                                            minHeight: 35,
                                        },
                                    }}
                                    size="small"
                                    name="route_name"
                                    className={`w-full`}
                                    placeholder="contoh: List Product"
                                    variant="outlined"
                                    value={
                                        state.hasOwnProperty(`route_name`)
                                            ? state[`route_name`]
                                            : ""
                                    }
                                    // onChange={handleChange}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <h3 className="fw-500 text-15 text-black">URL Endpoint</h3>
                                <TextField
                                    InputProps={{
                                        style: {
                                            borderRadius: 5,
                                            minHeight: 35,
                                        },
                                    }}
                                    size="small"
                                    name="url"
                                    className={`w-full`}
                                    placeholder="contoh: https://api.example.com/api/list-product"
                                    variant="outlined"
                                    value={
                                        state.hasOwnProperty(`url`)
                                            ? state[`url`]
                                            : ""
                                    }
                                    // onChange={handleChange}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <h3 className="fw-500 text-15 text-black">Method</h3>
                                <TextField
                                    InputProps={{
                                        style: {
                                            borderRadius: 5,
                                            minHeight: 35,
                                        },
                                    }}
                                    size="small"
                                    name="method"
                                    className={`w-full`}
                                    placeholder="POST/GET/PUT/DELETE"
                                    variant="outlined"
                                    value={
                                        state.hasOwnProperty(`method`)
                                            ? state[`method`]
                                            : ""
                                    }
                                    // onChange={handleChange}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            </Grid>
                        </Grid>
                    </div>
                </Card>

                <Card className="mt-5 py-8 px-5">
                    <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                        className="px-2 d-flex items-center"
                    >
                        <Grid item xs={12} sm>
                            <h2 className="fw-600 text-25 text-black my-auto">
                                Parameter API
                            </h2>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm
                            className="d-flex mr-15"
                            style={{ justifyContent: "flex-end" }}
                        >
                            <Link to={{
                                pathname: "/settings/apidocs/params",
                                state: locationState,
                            }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="px-7 py-2 border-2 text-white fw-500"
                                >
                                    <span className="">Lihat</span>
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                    {/* Menu */}
                    <div className="px-10 mx-5 ">
                        <Grid
                            container
                            className="mt-3"
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-end"
                        >

                            <Grid item md={12} className="mb-4">
                                <TableContainer component={Paper} className="mt-3">
                                    <Table className="buku-kas-table"
                                        style={{
                                            borderTop: "1px #e6e5e5 solid",
                                            marginTop: "20px",
                                        }}>
                                        <TableHead>
                                            <TableRow style={{ borderBottom: "1px #e6e5e5 solid" }}>
                                                <TableCell colSpan={1} className="text-bold-blue">No</TableCell>
                                                <TableCell colSpan={2} className="text-bold-blue">Parameter</TableCell>
                                                <TableCell colSpan={2} className="text-bold-blue">Type</TableCell>
                                                <TableCell colSpan={4} className="text-bold-blue">Value</TableCell>
                                                <TableCell colSpan={1} className="text-bold-blue">Wajib</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {detailApiDocs?.params?.map((value, index) => (
                                                <TableRow key={index}>
                                                    <TableCell colSpan={1}>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell colSpan={2}>{value.param_name}</TableCell>
                                                    <TableCell colSpan={2}>{value.param_type}</TableCell>
                                                    <TableCell colSpan={4}>{value.param_value}</TableCell>
                                                    <TableCell colSpan={1}>{value.param_required ? "Ya" : "Tidak"}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </div>
                </Card>

                <Card className="mt-5 py-8 px-5">
                    <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                        className="px-2 d-flex items-center"
                    >
                        <Grid item xs={12} sm>
                            <h2 className="fw-600 text-25 text-black my-auto">
                                Respon API
                            </h2>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm
                            className="d-flex mr-15"
                            style={{ justifyContent: "flex-end" }}
                        >
                            <Link to={{
                                pathname: "/settings/apidocs/responses",
                                state: locationState,
                            }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="px-7 py-2 border-2 text-white fw-500"
                                >
                                    <span className="">Lihat</span>
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>

                    {/* Menu */}
                    <div className="px-10 mx-5">
                        <Grid
                            container
                            className="mt-3"
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-end"
                        >

                            <Grid container spacing={2} className="mt-3">
                                {detailApiDocs?.responses?.map((value, index) => (
                                    value?.response_type === "success" ? (
                                        <Grid key={index} item lg={12} md={12} sm={12} xs={12}>
                                            <Card className="card-tab mx-5 px-5 p-4 mb-2" style={{ backgroundColor: "#f5f5f5" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} container direction="row" alignItems="center" justifyContent="space-between">
                                                        <h5 className="fw-bold text-bold-blue">Success Response</h5>
                                                        <Tooltip title="Salin Response" arrow>
                                                            <IconButton
                                                                color="dark"
                                                                size="small"
                                                                onClick={() => copyResponse(value.response_api, "success")}
                                                            >
                                                                {copyStatus === "Berhasil" && responseName === "success" ? (<Check style={{ color: "green" }} />) : copyStatus === "Gagal" && responseName === "success" ? (<Close style={{ color: "red" }} />) : (<FileCopy style={{ color: "#081B4B" }} />)}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <pre className="text-primary px-5 fw-bold">
                                                            {JSON.stringify(value.response_api, null, 2)}
                                                        </pre>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>) :
                                        value?.response_type === "error" ? (
                                            <Grid key={index} item lg={12} md={12} sm={12} xs={12}>
                                                <Card className="card-tab mx-5 px-5 p-4 mb-2" style={{ backgroundColor: "#f5f5f5" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} container direction="row" alignItems="center" justifyContent="space-between">
                                                            <h6 className="fw-bold text-bold-blue">Error Response</h6>
                                                            <Tooltip title="Salin Response" arrow>
                                                                <IconButton
                                                                    color="dark"
                                                                    size="small"
                                                                    onClick={() => copyResponse(value.response_api, "error")}
                                                                >
                                                                    {copyStatus === "Berhasil" && responseName === "error" ? (<Check style={{ color: "green" }} />) : copyStatus === "Gagal" && responseName === "error" ? (<Close style={{ color: "red" }} />) : (<FileCopy style={{ color: "#081B4B" }} />)}
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Divider />
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <pre className="text-danger px-5 fw-bold">
                                                                {JSON.stringify(value.response_api, null, 2)}
                                                            </pre>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        ) : ""
                                ))}
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default DetailApiDocumentation;
