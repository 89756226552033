import React from 'react';
import { Card} from '@material-ui/core';

const CardDataOrder = ({ status, count }) => {
  let backgroundColor = '';
  let statusText = '';
  let color = '';

  switch (status) {
    case 'complete':
      color = 'rgb(81, 175, 119)';
      statusText = 'Completed';
      backgroundColor = 'rgba(81, 175, 119, 0.05)';
      break;
    case 'failed':
      color = 'rgb(213, 84, 84)';
      statusText = 'Failed';
      backgroundColor = 'rgba(213, 84, 84, 0.05)';

      break;
    case 'expired':
      color = 'rgb(255, 156, 65)';
      statusText = 'Expired';
      backgroundColor = 'rgba(255, 156, 65, 0.05)';

      break;
    case 'processing':
      color = 'rgb(18, 83, 250)';
      statusText = 'Processing';
      backgroundColor = 'rgba(18, 83, 250, 0.05)';

      break;
    default:
        break;
  }

  return (
      <Card className="p-5 text-center" style={{ backgroundColor: backgroundColor }}>
        <h2 className="fw-bold" style={{ color: color }}>{count}</h2>
        <div className="d-flex justify-content-center">
          <h4 className="fw-bold" style={{ marginRight: '10px' }}>Order</h4>
          <h4 className="fw-bold" style={{ color: color }}>{statusText}</h4>
        </div>
      </Card>
  );
};

export default CardDataOrder;
