import React, {useState,  useLayoutEffect} from 'react'

import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import SimpleCard from '../../assets/components/cards/SimpleCard';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GeneralButton from '../../components/buttons/GeneralButton.jsx';
import {getAllCategories} from '../../redux/actions/GamesActions';
import TableGamesCategoryNew from './components/TableGamesCategoryNew';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1253FA',
    },
  },
});

const GamesCategory = () => {
   const [search, setSearch] = useState('');
    const [state, setState] = useState({
        categories:[],
    });

  const getData = () => {
    getAllCategories().then((res) => {
      const data = res.data?.data;
      if (search.trim() !== '') {
        const filteredData = data.filter((category) =>
          category.category_name.toLowerCase().includes(search.toLowerCase())
        );
        setState((prev) => ({
          ...prev,
          categories: filteredData,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          categories: data,
        }));
      }
    });
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    let data = e.target.value;
    setSearch(data);
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      getData()
    }
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        className="my-4 d-flex items-center"
      >
        <Grid item xs={12} sm>
          <h1 className="fw-600 m-0">Games Category</h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: "flex-end" }}
        >
          <Link to="/games/category/add">
            <ThemeProvider theme={theme}>
              <GeneralButton
                name="Add"
                icon={<AddIcon />}
                variant="contained"
              />
            </ThemeProvider>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div
          className="mt-2 mb-7 d-flex items-center"
          style={{ justifyContent: "flex-end" }}
        >
          <TextField
            size="small"
            variant="outlined"
            className="w-250"
            placeholder="Cari Kategori"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            // onChange={(e) => setSearch(e.target.value)}
            // onKeyDown={(e) => {
            //   if (e.keyCode == 13) {
            //     getData();
            //   }
            // }}
            value={search}
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <TableGamesCategoryNew data={state.categories} getData={getData} />
      </SimpleCard>
    </div>
  );
};

export default GamesCategory;
