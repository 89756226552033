import { authRoles } from '../../auth/authRoles';
import Faq from './FAQ/Faq';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import SnK from './SnK/SnK';

import ApiDocumentation from './ApiDocs/ApiDocumentation';
import AddApiDocumentation from './ApiDocs/AddApiDocumentation';
import EditApiDocumentation from './ApiDocs/EditApiDocumentation';
import DetailApiDocumentation from './ApiDocs/DetailApiDocumentation';

import ApiParams from './ApiDocs/ApiParameters/ApiParams';
import AddParamsApi from './ApiDocs/ApiParameters/AddParamsApi';
import EditParamsApi from './ApiDocs/ApiParameters/EditParamsApi';

import ApiResponses from './ApiDocs/ApiResponses/ApiResponses';
import AddResponsesApi from './ApiDocs/ApiResponses/AddResponsesApi';
import EditResponsesApi from './ApiDocs/ApiResponses/EditResponsesApi';

const SettingsRoutes = [
  {
    path: '/settings/faq',
    component: Faq,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/apidocs',
    component: ApiDocumentation,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/apidocs/add',
    component: AddApiDocumentation,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/apidocs/edit',
    component: EditApiDocumentation,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/apidocs/detail',
    component: DetailApiDocumentation,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/apidocs/params',
    component: ApiParams,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/apidocs/params/add',
    component: AddParamsApi,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/apidocs/params/edit',
    component: EditParamsApi,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/apidocs/responses',
    component: ApiResponses,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/apidocs/responses/add',
    component: AddResponsesApi,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/apidocs/responses/edit',
    component: EditResponsesApi,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/general_info',
    component: GeneralInfo,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/snk',
    component: SnK,
    auth: authRoles.adm,
    exact: true,
  },
  {
    path: '/settings/privacy_policy',
    component: PrivacyPolicy,
    auth: authRoles.adm,
    exact: true,
  },
];

export default SettingsRoutes;
