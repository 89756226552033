import { Button, Card, Grid, Icon } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UploadImageWithButton } from "../../components";
import {
  getSliders,
  addSlider,
  updateSlider,
  deleteSlider,
} from "../../redux/actions/SlidersAction";
import Swal from "sweetalert2";
import CardSliders from "../../components/cards/CardSliders";
import ModalAdd from "./modalAdd";

const Sliders = () => {
  const dispatch = useDispatch();
  const { dataSliders } = useSelector((state) => state.sliders);
  const getData = () => {
    dispatch(getSliders());
  };
  const [state, setState] = useState({
    showModalAdd: false,
    showModalEdit: false,
  });
  const [dataEdit, setDataEdit] = useState({});

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        deleteSlider(id)
          .then((res) => {
            if (res.data.code == 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
            }

            getData();
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  const handleAdd = () => {
    setState({
      ...state,
      showModalAdd: true,
    });
  };

  const handleEdit = (data) => {
    setState({
      ...state,
      showModalEdit: true,
    });
    setDataEdit(data);
  };

  const handleClose = () => {
    setState({
      ...state,
      showModalAdd: false,
      showModalEdit: false,
    });
  };

  useEffect(() => {
    if (dataSliders.length > 0) {
      let obj = {};
      for (let i = 0; i < dataSliders.length; i++) {
        obj = {
          ...obj,
          [`preview${dataSliders[i].id}`]: dataSliders[i].image,
        };
      }
      obj = {
        ...obj,
        [`previewNew`]: "",
      };

      setState((prev) => ({
        ...prev,
        ...obj,
      }));
    }
  }, [dataSliders]);

  return (
    <div className="m-sm-30 mt-7 text-black">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1 className="text-black fw-600 text-25 my-4">Sliders/Banner</h1>
        </Grid>
      </Grid>

      <Card className="mt-5 py-10 px-10">
        <Grid container spacing={3}>
          {dataSliders.length > 0 &&
            dataSliders.map((data, index) => (
              <Grid item xs={11} md={6} key={data.id}>
                {/* <h5 className="fw-500">Banner {index + 1}</h5>
                <UploadImageWithButton
                  uploadFoto={handleChangePhoto}
                  preview={state[`preview${data.id}`]}
                  formatIcon={false}
                  state={{ index: index + 1, id: data.id }}
                  handleDelete={handleDelete}
                  getData={getData}
                /> */}
                <CardSliders
                  title={"Banner " + (index + 1)}
                  data={data}
                  handleEdit={() => handleEdit(data)}
                  handleDelete={handleDelete}
                />
              </Grid>
            ))}
          <Grid item xs={11} md={6}>
            <CardSliders
              title={"Banner " + (dataSliders.length + 1)}
              handleAdd={handleAdd}
            />
            {/* <UploadImageWithButton
              uploadFoto={handleChangePhoto}
              preview={state[`previewNew`]}
              formatIcon={false}
              state={{ index: "New", id: "New" }}
              handleDelete={handleDelete}
              getData={getData}
            /> */}
          </Grid>
        </Grid>
      </Card>

      {state?.showModalAdd ? (
        <ModalAdd
          open={state?.showModalAdd}
          getData={getData}
          handleClose={handleClose}
        />
      ) : (
        ""
      )}
      {state?.showModalEdit ? (
        <ModalAdd
          open={state?.showModalEdit}
          getData={getData}
          handleClose={handleClose}
          data={dataEdit}
          type="update"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Sliders;
