import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Grid, Card, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getApiDocsResponses, getDetailApiDocs } from "../../../../redux/actions/Settings/ApiDocs";
import { Link } from "react-router-dom/cjs/react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import TableApiResponses from "../components/TableApiResponse";

const ApiResponses = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const locationState = location.state
    const { dataApiDocsResponses, detailApiDocs } = useSelector((state) => state.apiDocs);
    const id = locationState.api_docs_code || ""

    const getData = () => {
        dispatch(getApiDocsResponses(id))
        dispatch(getDetailApiDocs(id))
    }

    useEffect(() => { getData() }, [dispatch])

    // console.log('data response', dataApiDocsResponses)
    // console.log('data detail api', detailApiDocs)
    return (
        <div className="m-sm-30">
            <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                className="px-2 d-flex items-center"
            >
                <Grid item xs={12} sm>
                    <h2 className="fw-600 text-25 text-black my-auto">
                        Respon API {detailApiDocs?.route_name}
                    </h2>
                </Grid>
                <Grid item xs={12} lg={3} sm className="d-flex justify-end">
                    <Grid container spacing={2}>
                        <Grid item lg={6} xl={6}>
                            <Link to="/settings/apidocs">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="px-6 py-2 br-2 text-primary fw-500"
                                >
                                    <span className="">Kembali</span>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item lg={6} xl={6}>
                            <Link
                                to={{
                                    pathname: "/settings/apidocs/responses/add",
                                    state: locationState,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="px-8 py-2 br-2 text-white fw-500"
                                    startIcon={<AddIcon />}
                                >
                                    <span className="">Add</span>
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <div className="my-8">
                <Card className="py-8 px-5">
                    <TableApiResponses data={dataApiDocsResponses} getData={getData} />
                </Card>
            </div>
        </div>
    );
};

export default ApiResponses;
