import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import GeneralButton from "../buttons/GeneralButton";
import {
  saveSyncGameItemUnipin,
  syncGameItemUnipin,
} from "../../redux/actions/GamesActions";

const ModalSyncProductItem = ({
  handleClose,
  open,
  handleSave,
  data,
  detailGame = {},
}) => {
  const [loadingInstall, setLoadingInstall] = useState(false);
  const [loadingGameItem, setLoadingGameItem] = useState(false);
  const [indexSelected, setIndexSelected] = useState("");
  const [dataItem, setDataItem] = useState([]);
  const handleInstall = (index, item) => {
    setLoadingInstall(true);
    setIndexSelected(index);
    if (
      item.amount == "" ||
      item.price_not_member == "" ||
      item.price_reseller == ""
    ) {
      showAlert("Lengkapi semua harga !", false);
      setLoadingGameItem(false);
      setIndexSelected("");
      return;
    }
    const params = {
      title: item.name,
      game_code: detailGame.game_code,
      price: item.amount,
      price_not_member: item.price_not_member,
      price_reseller: item.price_reseller,
      denomination_id: item.id,
      from: "unipin",
    };

    saveSyncGameItemUnipin(params)
      .then((res) => {
        var newDataItem = dataItem;
        newDataItem[index].isInstalled = true;

        setDataItem([...newDataItem]);
        setLoadingInstall(false);
        setIndexSelected("");
      })
      .catch((err) => {
        // console.log(err);
        showAlert("Data gagal disimpan, coba beberapa saat lagi !", false);
        setLoadingInstall(false);
        setIndexSelected("");
      });
  };
  const showAlert = (text, success) => {
    Swal.fire({
      title: success ? "Berhasil" : "Oopss!",
      text: text,
      imageUrl: `/assets/images/icons/${
        success ? "ic_success" : "ic_error"
      }.svg`,
      imageWidth: 92,
      imageHeight: 92,
      confirmButtonText: "Ok",
      confirmButtonColor: "#0083E2",
    });
  };
  useEffect(() => {
    if (detailGame.game_code) {
      setLoadingGameItem(true);
      syncGameItemUnipin(detailGame.game_code)
        .then((res) => {
          var data = res.data.data;
          data.forEach((element) => {
            element.amount = parseInt(element.amount);
            element.price_not_member = element.amount;
            element.price_reseller = element.amount;
          });
          setDataItem(data);

          setLoadingGameItem(false);
        })
        .catch((error) => {
          console.log(error);
          showAlert("Gagal mengambil data", false);

          setLoadingGameItem(false);
          handleClose();
        });
    }
  }, [detailGame]);

  const handleChangePrice = (e, index, item) => {
    e.persist();
    var newDataItem = dataItem;
    newDataItem[index][e.target.name] = e.target.value;

    setDataItem([...newDataItem]);
  };

  const handleCloseModal = () => {
    setDataItem([]);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={true} fullWidth>
      <DialogTitle>Synchronize Game Denom {detailGame.game_name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Table
              className="buku-kas-table"
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              <TableHead>
                <TableRow style={{ borderBottom: "1px #e6e5e5 solid" }}>
                  <TableCell
                    colSpan={1}
                    className="font-medium text-15"
                    style={{ color: "#0a0a0a" }}
                    align="center"
                  >
                    No
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    className="font-medium text-15"
                    style={{ color: "#0a0a0a" }}
                  >
                    Denom Name
                  </TableCell>
                  <TableCell
                    colSpan={6}
                    className="font-medium text-15"
                    style={{ color: "#0a0a0a" }}
                  >
                    Setting Harga
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    className="font-medium text-15"
                    style={{ color: "#0a0a0a" }}
                    align="center"
                  >
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataItem.map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell
                      className="text-14 pl-3"
                      align="center"
                      style={{ color: "#0A0A0A" }}
                      colSpan={1}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      className="text-14"
                      style={{ color: "#0A0A0A" }}
                      colSpan={3}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell
                      className="text-14"
                      style={{ color: "#0A0A0A" }}
                      colSpan={6}
                    >
                      <div
                        className=" z-100 text-14 d-flex items-center"
                        style={{ gap: "16px" }}
                      >
                        <TextField
                          required={true}
                          size="small"
                          InputProps={{
                            style: {
                              borderRadius: 5,
                              minHeight: 46,
                            },
                          }}
                          className="w-full"
                          value={item.amount}
                          name="amount"
                          placeholder="Price Member"
                          variant="outlined"
                          onChange={(e) => handleChangePrice(e, index, item)}
                          label="Price member"
                          disabled={item.isInstalled}
                          type="number"
                        />
                        <TextField
                          required={true}
                          size="small"
                          InputProps={{
                            style: {
                              borderRadius: 5,
                              minHeight: 46,
                            },
                          }}
                          className="w-full"
                          value={item.price_not_member}
                          name="price_not_member"
                          placeholder="Price Non Member"
                          variant="outlined"
                          onChange={(e) => handleChangePrice(e, index, item)}
                          label="Price non member"
                          disabled={item.isInstalled}
                          type="number"
                        />
                        <TextField
                          required={true}
                          size="small"
                          InputProps={{
                            style: {
                              borderRadius: 5,
                              minHeight: 46,
                            },
                          }}
                          className="w-full"
                          value={item.price_reseller}
                          name="price_reseller"
                          placeholder="Price Reseller"
                          variant="outlined"
                          onChange={(e) => handleChangePrice(e, index, item)}
                          label="Price Reseller"
                          disabled={item.isInstalled}
                          type="number"
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className="text-14 pl-3"
                      align="center"
                      style={{ color: "#0A0A0A" }}
                      colSpan={3}
                    >
                      {!item.isInstalled ? (
                        <GeneralButton
                          name={
                            loadingInstall && indexSelected == index
                              ? "Loading..."
                              : "Save"
                          }
                          data={() => handleInstall(index, item)}
                          disabled={loadingInstall}
                        />
                      ) : (
                        <GeneralButton
                          name="Denom Saved"
                          icon={<CheckCircleOutline />}
                          disabled={true}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {dataItem.length <= 0 && !loadingGameItem && (
                  <TableRow hover>
                    <TableCell
                      className="text-14 pl-3"
                      align="center"
                      style={{ color: "#0A0A0A" }}
                      colSpan={12}
                    >
                      Game Denom sudah up to date
                    </TableCell>
                  </TableRow>
                )}
                {loadingGameItem && (
                  <TableRow hover>
                    <TableCell
                      className="text-14 pl-3"
                      align="center"
                      style={{ color: "#0A0A0A" }}
                      colSpan={12}
                    >
                      <CircularProgress color="primary" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className="flex-column-reverse flex-md-row">
          <Button
            color="primary"
            className="w-full radius-6 text-capitalize py-2"
            disableElevation
            onClick={handleCloseModal}
            variant="outlined"
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ModalSyncProductItem;
