import { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import Swal from "sweetalert2";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { deleteApiDocsParam } from "../../../../redux/actions/Settings/ApiDocs";


export default function ActionTableParameter({ item, getData }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        getData();
        setAnchorEl(null);
    };

    const handleDeleteAction = (id) => {
        Swal.fire({
            title: "Hapus",
            text: "Apakah kamu yakin",
            showCancelButton: true,
            confirmButtonText: "Yakin",
            cancelButtonText: "Batal",
            icon: "warning",
        }).then((res) => {
            const body = {
                param_code: id
            }
            if (res.isConfirmed) {
                deleteApiDocsParam(body)
                    .then((res) => {
                        if (res.data.code == 0) {
                            console.log(res);
                            Swal.fire({
                                title: "Berhasil",
                                text: "Data berhasil dihapus",
                                timer: 2000,
                                icon: "success",
                            });
                        }
                        handleClose();
                    })
                    .catch((err) => {
                        console.log("err", err);
                        Swal.fire({
                            title: "gagal",
                            text: "Data Gagal dihapus",
                            timer: 2000,
                            icon: "error",
                        });
                        handleClose();
                    });
            }
        });
    };

    // console.log('code', item.api_docs_code)

    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="elevation-z0"
            >
                <MoreHorizIcon />
            </Button>


            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Link
                    to={{
                        pathname: "/settings/apidocs/params/edit",
                        state: item,
                    }}
                >
                    <MenuItem className="mb-3" onClick={() => handleClose}>
                        <span className="">
                            <BorderColorIcon style={{ color: "#1253FA" }} />
                        </span>
                        <span className="pl-3" style={{ color: "#1253FA" }}>
                            Edit Data
                        </span>
                    </MenuItem>
                </Link>
                <MenuItem
                    className=""
                    onClick={() => handleDeleteAction(item.param_code)}
                >
                    <span className="text-red">
                        <DeleteOutlineOutlinedIcon />
                    </span>
                    <span className="pl-3 text-red">Hapus Data</span>
                </MenuItem>
            </Menu>
        </div>
    );
}
