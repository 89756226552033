import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Card, Icon } from "@material-ui/core";
import Swal from "sweetalert2";
import SelectValue from "../components/SelectValue";
import { getApiDocs } from "../../../../redux/actions/Settings";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from 'react-router-dom';
import { editApiDocsParam } from "../../../../redux/actions/Settings/ApiDocs";
import { Link } from "react-router-dom/cjs/react-router-dom";

const EditParamsApi = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const locationState = location.state;
    const { dataApiDocs } = useSelector((state) => state.apiDocs);

    const getData = () => {
        const params = `?search=`
        dispatch(getApiDocs(params))
    }

    useEffect(() => { getData() }, [dispatch])

    const [state, setState] = useState({
        param_name: locationState.param_name || "",
        param_type: locationState.param_type || "",
        param_value: locationState.param_value || "",
        param_required: locationState.param_required || "",
        api_docs_code: locationState.api_docs_code || "",
    });

    const handleApiDocsChange = (newValue) => {
        setState((prev) => ({
            ...prev,
            api_docs_code: newValue.api_docs_code
        }));
    };

    const handleRequiredChange = (newValue) => {
        setState((prev) => ({
            ...prev,
            param_required: newValue.param_required
        }));
    };

    const apiDocs = dataApiDocs.map((item) => ({
        value: item.api_docs_code,
        text: item.route_name,
    }))

    const required = [
        { value: 0, text: "Tidak" },
        { value: 1, text: "Ya" }
    ]

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = () => {
        // console.log(state)
        // console.log('lok', locationState)
        // console.log(locationState.param_required)
        editApiDocsParam(locationState.param_code, state)
            .then((res) => {
                Swal.fire({
                    icon: "success",
                    title: "Berhasil",
                    text: "Parameter API berhasil diperbarui!",
                    showConfirmButton: true,
                    timer: 2000,
                });

                history.push({ pathname: "/settings/apidocs/params", state: locationState })
            })

            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Gagal",
                    text: "Gagal menyimpan parameter API!",
                    showConfirmButton: true,
                    timer: 2000,
                });
            });
    };

    // console.log('data', locationState)

    return (
        <div className="m-sm-30">
            {/* Head */}
            <Grid
                container
                spacing={1}
                className="d-flex items-center"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={12} lg={9} sm>
                    <h2 className="fw-600 text-25 text-black">Edit Parameter</h2>
                </Grid>
                <Grid item xs={12} lg={3} sm className="justify-end">
                    <Grid container spacing={2}>
                        <Grid item lg={6} xl={6}>
                            <Link to={{
                                pathname: "/settings/apidocs/params",
                                state: locationState,
                            }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="px-5 py-2 border-2 text-primary fw-500"
                                >
                                    <span className="">Kembali</span>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item lg={6} xl={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="px-5 py-2 border-2 text-white fw-500"
                                onClick={handleSubmit}
                            >
                                <span className="">Simpan</span>
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <div className="my-8">
                <Card className="py-10 px-5">
                    {/* Menu */}
                    <div className="mx-2 px-4">
                        <Grid
                            container
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-end"
                        >
                            <Grid item xs={12} sm={12}>
                                <h3 className="mb-5 fw-500 text-15 text-black">Nama Parameter</h3>
                                <TextField
                                    InputProps={{
                                        style: {
                                            borderRadius: 5,
                                            minHeight: 35,
                                        },
                                    }}
                                    size="small"
                                    name="param_name"
                                    className={`w-full`}
                                    placeholder="Nama parameter"
                                    variant="outlined"
                                    value={
                                        state.hasOwnProperty(`param_name`)
                                            ? state[`param_name`]
                                            : ""
                                    }
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <h3 className="mb-5 fw-500 text-15 text-black">Tipe Parameter</h3>
                                <TextField
                                    InputProps={{
                                        style: {
                                            borderRadius: 5,
                                            minHeight: 35,
                                        },
                                    }}
                                    size="small"
                                    name="param_type"
                                    className={`w-full`}
                                    placeholder="Tipe parameter"
                                    variant="outlined"
                                    value={
                                        state.hasOwnProperty(`param_type`)
                                            ? state[`param_type`]
                                            : ""
                                    }
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <h3 className="mb-5 fw-500 text-15 text-black">Value</h3>
                                <TextField
                                    InputProps={{
                                        style: {
                                            borderRadius: 5,
                                            minHeight: 35,
                                        },
                                    }}
                                    size="small"
                                    name="param_value"
                                    className={`w-full`}
                                    placeholder="Value"
                                    variant="outlined"
                                    value={
                                        state.hasOwnProperty(`param_value`)
                                            ? state[`param_value`]
                                            : ""
                                    }
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h3 className="mb-5 fw-500 text-15 text-black">Wajib di isi</h3>
                                <SelectValue state={state.param_required} name="param_required" setState={handleRequiredChange} dataSelect={required} required={true} label="Pilih value" width="100%" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h3 className="mb-5 fw-500 text-15 text-black">API Dokumentasi</h3>
                                <SelectValue state={locationState.api_docs_code} name="api_docs_code" setState={handleApiDocsChange} dataSelect={apiDocs} disabled={true} width="100%" />
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default EditParamsApi;
