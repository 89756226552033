import React, { useLayoutEffect, useState } from "react";
import {
  TextField,
  Icon,
  InputAdornment,
  Grid,
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
} from "@material-ui/core";

import SimpleCard from "../../assets/components/cards/SimpleCard";
import { useDispatch, useSelector } from "react-redux";
import { getDataLog } from "../../redux/actions/GlobalActions";
import ModalDetail from "./modalDetail";

const DataLog = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  const [modalData, setDataModal] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);

  const getData = () => {
    dispatch(getDataLog(search));
  };

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };

  const showModalDetail = (data) => {
    setDataModal(data);
    setShowModal(!showModal);
  };

  const handleClose = () => {
    setShowModal(!showModal);
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  const { dataLog } = useSelector((state) => state.global);

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1 className="text-black fw-600 text-25 my-auto">Data Log</h1>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div className="mt-2 mb-4 d-flex items-center justify-end pt-5">
          <TextField
            size="small"
            variant="outlined"
            className={`w-250`}
            placeholder="Cari..."
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
              style: {
                borderRadius: 5,
                minHeight: 46,
                backgroundColor: "#FFFFFF",
              },
            }}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              handleChangePage(e, 0);
            }}
          />
        </div>
        <div className="w-full overflow-auto bg-white">
          <Table className="buku-kas-table mt-10 border-y-black-1">
            <TableHead>
              <TableRow className="border-y-black-1">
                <TableCell
                  colSpan={1}
                  className="font-medium text-15 text-black"
                  align="center"
                >
                  No
                </TableCell>
                <TableCell
                  colSpan={3}
                  className="font-medium text-15 text-black"
                >
                  From
                </TableCell>
                <TableCell
                  colSpan={3}
                  className="font-medium text-15 text-black"
                >
                  Kode
                </TableCell>
                <TableCell
                  colSpan={2}
                  className="font-medium text-15 text-black"
                >
                  Notes
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="font-medium text-15 text-black text-center"
                  align="center"
                >
                  Aksi
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataLog
                .filter((item) =>
                  item.unique_code.toLowerCase().includes(search.toLowerCase())
                )
                .map((item, index) => (
                  <TableRow hover key={item.id}>
                    <TableCell
                      align="center"
                      className="text-14 pl-3 text-black"
                      colSpan={1}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell className="text-14 text-black" colSpan={3}>
                      {item.from}
                    </TableCell>
                    <TableCell className="text-14 text-black" colSpan={3}>
                      {item.unique_code}
                    </TableCell>
                    <TableCell className="text-14 text-black" colSpan={2}>
                      {item.notes}
                    </TableCell>
                    <TableCell className="pl-3" colSpan={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="text-white border-radius-4"
                        size="small"
                        onClick={() => showModalDetail(item)}
                      >
                        Detail
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <ModalDetail
            data={modalData}
            open={showModal}
            handleClose={handleClose}
          />

          <TablePagination
            className="px-10 my-7"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={
              dataLog?.filter((item) =>
                item.unique_code.toLowerCase().includes(search.toLowerCase())
              )?.length
                ? dataLog?.length
                : 0
            }
            rowsPerPage={state.rowsPerPage}
            labelRowsPerPage={"From"}
            page={state.page}
            backIconButtonProps={{
              "aria-label": "Previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next page",
            }}
            backIconButtonText="Previous page"
            nextIconButtonText="Next page"
            onPageChange={handleChangePage}
            onRowsPerPageChange={setRowsPerPage}
          />
        </div>
      </SimpleCard>
    </div>
  );
};

export default DataLog;
