import { useEffect, useState } from "react";
import React from "react";
import {
    TablePagination,
    Table,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Button,
} from "@material-ui/core";
import {
    SortableContainer,
    SortableHandle,
    SortableElement,
    arrayMove,
} from "react-sortable-hoc";
import MenuActions from "./MenuActions";
import { updatePositionApiDocs } from "../../../../redux/actions/Settings/ApiDocs";
import Swal from "sweetalert2";

const RenderTable = ({ data, state, search, getData, setState, setOrderTriggered }) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setState({
            ...state,
            apiDocs: arrayMove(state.apiDocs, oldIndex, newIndex),
        });
        setOrderTriggered(true);
    };

    //  Component which uses drag-n-drop activation when clicking inside the component
    const DragHandle = SortableHandle(({ style }) => (
        <span style={{ ...style, ...{ cursor: "move" } }}> {"::::"} </span>
    ));

    // Universal component for turning a TableBody into a sortable container
    const TableBodySortable = SortableContainer(
        ({ children, displayRowCheckbox }) => (
            <TableBody displayRowCheckbox={displayRowCheckbox}>{children}</TableBody>
        )
    );

    // The string is necessary for our custom body to be perceived as a TableBody and in this case there will be no error
    TableBodySortable.muiName = "TableBody";

    const Row = SortableElement(({ data, ...other }) => {
        return (
            <TableRow>
                <TableCell colSpan={1}>
                    <DragHandle />
                </TableCell>
                <TableCell
                    style={{ color: "#0A0A0A" }}
                    className="text-14"
                    align="center"
                >
                    {data.position + 1 || "-"}
                </TableCell>
                <TableCell colSpan={3}>
                    {data.route_name}
                </TableCell>
                <TableCell colSpan={2}>
                    {data.method}
                </TableCell>
                <TableCell colSpan={5}>
                    {data.url}
                </TableCell>
                <TableCell align="left" colSpan={1}>
                    {/* <MenuCategory
            type={"category"}
            item={data}
            getData={getData}
            editPath={`/games/category/edit/${data.category_code}`}
          /> */}
                    <MenuActions
                        item={data}
                        getData={getData}
                    ></MenuActions>
                </TableCell>
            </TableRow>
        );
    });

    return data?.length > 0 ? (
        <TableBodySortable
            onSortEnd={onSortEnd}
            useDragHandle
            displayRowCheckbox={false}
        >
            {data.map((row, index) => {
                return <Row index={index} key={row.id} data={row} />;
            })}
        </TableBodySortable>
    ) : (
        <>
            <TableCell colSpan={12} align="center">
                Data kosong
            </TableCell>
        </>
    );
};

const TableApiDocumentation = ({ search, data = [], getData }) => {
    const [state, setState] = useState({
        page: 0,
        rowsPerPage: 10,
        data_checked: [],
        allChecked: false,
        status: "",
        apiDocs: data,
    });
    const [orderTriggered, setOrderTriggered] = useState(false)

    const setPage = (page) => {
        setState({
            ...state,
            page,
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const setRowsPerPage = (event) => {
        setState({
            ...state,
            rowsPerPage: event.target.value,
        });
    };

    useEffect(() => {
        setState({
            ...state,
            apiDocs: data,
        });
    }, [data]);

    const handleSave = () => {
        console.log('data', { data: state.apiDocs })
        updatePositionApiDocs({ data: state.apiDocs }).then((res) => {
            console.log(res);
            Swal.fire("Berhasil", "Pengurutan disimpan !", "success");
            setOrderTriggered(false);
            getData();
        })
            .catch(err => {
                Swal.fire("Gagal !", "Pengurutan gagal disimpan !", "error");
            })
    };

    return (
        <div className="my-5">
            <div className="w-full overflow-auto bg-white izin-cuti-tabs-slide">
                <Table
                    className="buku-kas-table"
                    style={{
                        borderTop: "1px #e6e5e5 solid",
                        marginTop: "20px",
                    }}
                >
                    <TableHead>
                        <TableRow style={{ borderBottom: "1px #e6e5e5 solid" }}>
                            <TableCell
                                colSpan={1}
                                className="font-medium text-15"
                                style={{ color: "#0a0a0a" }}
                            >
                                Drag
                            </TableCell>
                            <TableCell
                                className="font-medium text-15"
                                style={{ color: "#0a0a0a" }}
                                align="center"
                            >
                                Posisi
                            </TableCell>
                            <TableCell
                                colSpan={3}
                                className="font-medium text-15"
                                style={{ color: "#0a0a0a" }}
                            >
                                Nama Route
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                className="font-medium text-15"
                                style={{ color: "#0a0a0a" }}
                            >
                                Method
                            </TableCell>
                            <TableCell
                                colSpan={5}
                                className="font-medium text-15"
                                style={{ color: "#0a0a0a" }}
                            >
                                URL Endpoint
                            </TableCell>
                            <TableCell
                                colSpan={1}
                                className="font-medium text-15 ps-3"
                                style={{ color: "#0a0a0a" }}
                                align="left"
                            >
                                Aksi
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {/* <TableBody> */}
                    <RenderTable
                        data={state.apiDocs.slice(
                            state.page * state.rowsPerPage,
                            state.page * state.rowsPerPage + state.rowsPerPage
                        )}
                        state={state}
                        getData={getData}
                        search={search}
                        setState={setState}
                        checkedData={state.data_checked}
                        setOrderTriggered={setOrderTriggered}
                    />
                    {/* </TableBody> */}
                </Table>

                {orderTriggered ? (
                    <div className="d-flex justify-content-between align-content-center align-items-center ml-5">
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className="text-white text-capitalize pc-2 ml-3"
                            disableElevation
                            style={{
                                borderRadius: 5,
                            }}
                            onClick={handleSave}
                        >
                            Save Pengurutan
                        </Button>
                        <TablePagination
                            className="px-16 my-7"
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data?.length ? data?.length : 0}
                            rowsPerPage={state.rowsPerPage}
                            labelRowsPerPage={"From"}
                            page={state.page}
                            backIconButtonProps={{
                                "aria-label": "Previous page",
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Next page",
                            }}
                            backIconButtonText="Previous page"
                            nextIconButtonText="Next page"
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={setRowsPerPage}
                        />
                    </div>
                ) : (
                    <TablePagination
                        className="px-16 my-7"
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data?.length ? data?.length : 0}
                        rowsPerPage={state.rowsPerPage}
                        labelRowsPerPage={"From"}
                        page={state.page}
                        backIconButtonProps={{
                            "aria-label": "Previous page",
                        }}
                        nextIconButtonProps={{
                            "aria-label": "Next page",
                        }}
                        backIconButtonText="Previous page"
                        nextIconButtonText="Next page"
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={setRowsPerPage}
                    />
                )}
            </div>
        </div>
    );
};

export default TableApiDocumentation;
