import { useState } from "react";
import React from "react";
// import MenuDenda from "./MenuDenda";
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Avatar,
  Checkbox,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import MenuCategory from "./MenuCategory";
import {
  deleteBulkVoucher,
  updateBulkStatusVoucher,
} from "../../../redux/actions/GamesActions";
import Swal from "sweetalert2";
import { CheckBox } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const RenderTable = ({
  data,
  state,
  search,
  getData,
  voucher,
  setState,
  checklist,
  checkedData,
}) => {
  const handleCheckboxChange = (event) => {
    console.log(event.target);
    // checked or not
    const checked = event.target.checked;
    // get item_code
    const item_code = event.target.name;
    // console.log("target: ", target);
    if (checked === true) {
      setState((prevState) => ({
        ...prevState,
        data_checked: [...prevState.data_checked, item_code],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data_checked: prevState.data_checked.filter(
          (item) => item !== item_code
        ),
      }));
    }
    console.log("item_code: ", item_code);
  };

  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };
  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) => (
        <TableRow hover key={index}>
          {checklist ? (
            <TableCell
              className="text-14 pl-1 "
              align="center"
              style={{ color: "#0A0A0A" }}
              colSpan={1}
            >
              <Checkbox
                color="primary"
                onChange={handleCheckboxChange}
                name={item.item_code}
                checked={
                  checkedData.filter((dt) => dt === item.item_code).length > 0
                    ? true
                    : false
                }
              />
            </TableCell>
          ) : (
            ""
          )}
          <TableCell
            className="text-14 pl-3"
            align="center"
            style={{ color: "#0A0A0A" }}
            colSpan={1}
          >
            {index + 1 + handleNumbering()}
          </TableCell>
          <TableCell colSpan={voucher ? 5 : 10}>
            {voucher ? item.redeem_code : item.category_name}
          </TableCell>
          {voucher && <TableCell colSpan={5}>{item.title}</TableCell>}
          {voucher && <TableCell colSpan={5}>{item.games_title}</TableCell>}
          {voucher && <TableCell colSpan={2}>{item.voucher_status}</TableCell>}

          <TableCell align="center" colSpan={1}>
            <MenuCategory
              type={voucher ? "voucher" : "category"}
              item={item}
              getData={getData}
              editPath={
                voucher
                  ? `/games/voucher/edit/${item.item_code}`
                  : `/games/category/edit/${item.category_code}`
              }
            />
          </TableCell>
        </TableRow>
      ))
  ) : (
    <>
      <TableCell colSpan={voucher ? 20 : 12} align="center">
        Data kosong
      </TableCell>
    </>
  );
};

const TableGamesCategory = ({
  search,
  data = [],
  getData,
  voucher,
  checklist = false,
}) => {
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    data_checked: [],
    allChecked: false,
    status: "",
  });

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    // checked or not
    const checked = event.target.checked;
    if (checked === true) {
      var start = state.page;
      var end = (state.page + 1) * state.rowsPerPage;
      if (state.page > 0) {
        start = state.rowsPerPage + 1;
      }
      console.log(start, end);
      setState((prevState) => ({
        ...prevState,
        data_checked: data.slice(start, end).map((item) => item.item_code),
        allChecked: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data_checked: [],
        allChecked: false,
      }));
    }
  };

  const handleChange = ({ target: { value, name } }) => {
    setState((pref) => ({
      ...pref,
      status: value,
    }));
  };

  const updateBulkStatus = () => {
    const params = {
      status: state.status,
      data: state.data_checked,
    };
    updateBulkStatusVoucher(params)
      .then((res) => {
        Swal.fire({
          title: "Berhasil",
          text: "Data berhasil diupdate",
          timer: 2000,
          icon: "success",
        });
        setState((prevState) => ({
          ...prevState,
          data_checked: [],
          allChecked: false,
        }));
        getData();
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire({
          title: "gagal",
          text: "Data Gagal Diupdate",
          timer: 2000,
          icon: "error",
        });
      });
  };

  const deleteBulk = () => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        const params = {
          data: state.data_checked,
        };
        deleteBulkVoucher(params)
          .then((res) => {
            Swal.fire({
              title: "Berhasil",
              text: "Data berhasil dihapus",
              timer: 2000,
              icon: "success",
            });
            setState((prevState) => ({
              ...prevState,
              data_checked: [],
              allChecked: false,
            }));
            getData();
          })
          .catch((err) => {
            console.log("err", err);
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  return (
    <div className="w-full overflow-auto bg-white izincuti-tabs-slide">
      <Table
        className="buku-kas-table"
        style={{
          borderTop: "1px #e6e5e5 solid",
          marginTop: "20px",
        }}
      >
        <TableHead>
          <TableRow style={{ borderBottom: "1px #e6e5e5 solid" }}>
            {checklist ? (
              <TableCell
                colSpan={1}
                className="font-medium text-15 pl-1"
                style={{ color: "#0a0a0a" }}
                align="center"
              >
                <Checkbox
                  color="primary"
                  onChange={handleCheckboxChange}
                  checked={state.allChecked}
                />
              </TableCell>
            ) : (
              ""
            )}
            <TableCell
              colSpan={1}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
              align="center"
            >
              No
            </TableCell>
            <TableCell
              colSpan={voucher ? 5 : 10}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
            >
              {voucher ? "Redeem Code" : "Kategori"}
            </TableCell>
            {voucher && (
              <TableCell
                colSpan={5}
                className="font-medium text-15"
                style={{ color: "#0a0a0a" }}
              >
                Game Item
              </TableCell>
            )}
            {voucher && (
              <TableCell
                colSpan={5}
                className="font-medium text-15"
                style={{ color: "#0a0a0a" }}
              >
                Game
              </TableCell>
            )}
            {voucher && (
              <TableCell
                colSpan={2}
                className="font-medium text-15"
                style={{ color: "#0a0a0a" }}
              >
                Status
              </TableCell>
            )}
            <TableCell
              colSpan={1}
              className="font-medium text-15"
              style={{ color: "#0a0a0a" }}
              align="center"
            >
              Aksi
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RenderTable
            data={data}
            state={state}
            getData={getData}
            search={search}
            voucher={voucher}
            setState={setState}
            checkedData={state.data_checked}
            checklist={checklist}
          />
        </TableBody>
      </Table>

      {checklist ? (
        <div className="d-flex justify-content-between align-content-center align-items-center">
          <div className="d-flex align-content-center align-items-center">
            <div className="d-flex align-content-center align-items-center">
              <CheckBox className="ml-5" htmlColor="#BABEC1" />
              <p className="p-0 m-0" style={{ color: "#BABEC1" }}>
                {state?.data_checked?.length}/{data.length} Data
              </p>
            </div>
            {state?.data_checked?.length > 0 ? (
              <div className="d-flex align-content-center align-items-center ml-5">
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      size="medium"
                      style={{ fontWeight: 700 }}
                    />
                  )}
                  onChange={handleChange}
                  value={state.status}
                  style={{ borderRadius: 5, maxHeight: 35 }}
                  size={"sm"}
                  className="bg-white p-0"
                  variant="outlined"
                  displayEmpty
                >
                  <MenuItem key={0} value={""} text={"Status"}>
                    Status
                  </MenuItem>
                  <MenuItem key={1} value={1} text={"Active"}>
                    Active
                  </MenuItem>
                  <MenuItem key={2} value={0} text={"Non Active"}>
                    Non Active
                  </MenuItem>
                </Select>
                {state.status !== "" ? (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className="text-white w-full text-capitalize pc-2 ml-3"
                    disableElevation
                    style={{
                      borderRadius: 5,
                    }}
                    onClick={updateBulkStatus}
                  >
                    Save
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  type="button"
                  variant="contained"
                  color="danger"
                  className="text-white w-full text-capitalize pc-2 ml-3 bg-danger"
                  disableElevation
                  style={{
                    borderRadius: 5,
                  }}
                  onClick={deleteBulk}
                >
                  Delete
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
          <TablePagination
            className="px-16 my-7"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.length ? data?.length : 0}
            rowsPerPage={state.rowsPerPage}
            labelRowsPerPage={"From"}
            page={state.page}
            backIconButtonProps={{
              "aria-label": "Previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next page",
            }}
            backIconButtonText="Previous page"
            nextIconButtonText="Next page"
            onPageChange={handleChangePage}
            onRowsPerPageChange={setRowsPerPage}
          />
        </div>
      ) : (
        <TablePagination
          className="px-16 my-7"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.length ? data?.length : 0}
          rowsPerPage={state.rowsPerPage}
          labelRowsPerPage={"From"}
          page={state.page}
          backIconButtonProps={{
            "aria-label": "Previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next page",
          }}
          backIconButtonText="Previous page"
          nextIconButtonText="Next page"
          onPageChange={handleChangePage}
          onRowsPerPageChange={setRowsPerPage}
        />
      )}
    </div>
  );
};

export default TableGamesCategory;
