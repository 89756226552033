import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Icon,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export default function SelectText({
  dataSelect = [],
  state = "",
  required = false,
  setState = "",
  size = "small",
  label = "",
  width = "171px",
  name = "",
  scaleY = "1",
  menuItemFontSize = "text-14",
}) {
  const handleChange = (e) => {
    setState(e.target.value);
  };

  const useStyles = makeStyles((theme) => ({
    input: {
      transform: "scaleY(0.88)",
    },
  }));

  const handleClasses = () => {
    if (scaleY === "1.25") {
      return classes.input;
    } else {
      return "";
    }
  };

  const classes = useStyles();
  return (
    <div>
      <FormControl
        size={size}
        variant="outlined"
        className="bg-white rounded-lg"
        style={{ width: width }}
        required={required}
      >
        <InputLabel htmlFor="outlined-age-native-simple">
          <span style={{ color: "#555c64b7" }} className="my-auto bg-white">
            {label}
          </span>
        </InputLabel>
        <Select
          IconComponent={() => (
            <KeyboardArrowDownIcon size="medium" style={{ fontWeight: 700 }} />
          )}
          onChange={handleChange}
          value={state}
          inputProps={{
            name,
            className: handleClasses(),
          }}
          size={size}
          className="bg-white"
          style={{
            transform: `scaleY(${scaleY})`,
            paddingRight: "16px",
          }}
        >
          {dataSelect.map((data) => (
            <MenuItem
              key={data}
              value={data}
              text={data}
              className={`${menuItemFontSize} "bg-white"`}
              style={{
                transform: `scaleY(${scaleY === "1.25" ? "0.92" : "1"})`,
              }}
            >
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
