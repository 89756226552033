import React from "react";
import { Card, Dialog, Grid } from "@material-ui/core";

const ModalDetail = ({ data, handleClose, open }) => {
  return (
    <Dialog open={open} onClose={handleClose} scroll="body" size="lg">
      <Card className="p-5">
        <Grid container spacing={4}>
          <Grid item xs={12} className="text-17 text-black fw-600">
            Detail
          </Grid>
          <Grid item xs={6} sm={6}>
            <h5>From</h5>
            <p>{data.from}</p>
          </Grid>
          <Grid item xs={6} sm={6}>
            <h5>Kode</h5>
            <p>{data.unique_code}</p>
          </Grid>
          <Grid item xs={6} sm={6}>
            <h5>Notes</h5>
            <p>{data.notes}</p>
          </Grid>
          <Grid item xs={6} sm={6}>
            <h5>Tanggal Dibuat</h5>
            <p>{data.created_at}</p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <h5>Data Diterima</h5>
            <pre>{data.response}</pre>
          </Grid>
        </Grid>
      </Card>
    </Dialog>
  );
};

export default ModalDetail;
