import {
  Card,
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import React, { useLayoutEffect, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "../../../styles/css/DetailUser.css";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import GeneralButton from "../../components/buttons/GeneralButton.jsx";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  addVoucherGames,
  getProductList,
  importVoucherGames,
} from "../../redux/actions/GamesActions";

import SelectWithTextAndValue from "../../components/select/SelectWithTextAndValue";

const AddGamesVoucher = () => {
  const dispatch = useDispatch();

  const [product, setProduct] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formatFile, setFormatFile] = useState(null);
  const [file, setFile] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [submit, setSubmit] = useState(false);
  const [isActive, setIsActive] = useState({
    isActive: 1,
  });

  const handleChange = ({ target: { value } }) => {
    setIsActive((pref) => ({
      ...pref,
      isActive: value,
    }));
  };

  const handleChangeInput = (event) => {
    const value = event.target.value;
    setInputValue(value);
    // const lines = value.split("\n");
    // setResultArray(lines);
  };

  const getDataProduct = () => {
    getProductList().then((res) => {
      const data = res.data?.data;
      setProduct(
        data.map((item) => ({
          text: item.title,
          value: item.code,
        }))
      );
    });
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "text/plain" || fileType === "text/csv") {
        setFormatFile(fileType);
        setSelectedFile(file);
      } else {
        Swal.fire("Oopss!", "Format file tidak didukung!", "error");
      }
    }
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
  };

  const [state, setState] = useState({
    product: "",
    productImport: ""
  });

  useLayoutEffect(() => {
    console.log("uselayouteffect");
  }, []);

  const history = useHistory();

  const handleSubmit = () => {
    const data = {
      product: state.product,
      isActive: isActive.isActive,
      redeem_code: inputValue,
    };
    addVoucherGames(data)
      .then(() => {
        setSubmit(false);
        history.push("/games/voucher");
        Swal.fire("Success!", "Voucher berhasil disimpan", "success");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          "Oopss!",
          "Data Kategory gagal disimpan",
          "Voucher gagal disimpan"
        );
        setSubmit(false);
      });
  };

  const uploadFile = (params) => {
    const data = {
      item_code: state.productImport,
      file: params,
      formatFile: formatFile,
    };
    importVoucherGames(data)
      .then(() => {
        setSubmit(false);
        history.push("/games/voucher");
        Swal.fire("Success!", "Voucher berhasil disimpan", "success");
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          "Oopss!",
          "Data Kategory gagal disimpan",
          "Voucher gagal disimpan"
        );
        setSubmit(false);
      });
  };
  const handleSubmitFile = () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64File = reader.result;
        uploadFile(base64File);
      };
      reader.readAsDataURL(selectedFile);
    }

   
  };

  // ...... Dicomment dulu
  // useEffect(() => {
  //   if (!file) {
  //     return;
  //   }
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     const data = reader.result.split("\n");
  //     setData(
  //       data.map((line) => {
  //         return {
  //           code: state.product,
  //           status: state.category_name,
  //           redeem_code: line.replace("\r", ""),
  //         };
  //       })
  //     );
  //   };
  //   reader.readAsText(file);
  // }, [file]);

  useEffect(() => {
    getDataProduct();
  }, []);

  return (
    <div className="analytics m-sm-30 mt-7 text-black">
      <h1 className="fw-600 m-0">Add Voucher</h1>
      <Grid
        item
        xs={12}
        sm
        className="d-flex mr-8"
        style={{ justifyContent: "flex-end" }}
      ></Grid>
      <Card className="mt-5 py-10 px-10">
        <div className="mx-8 px-10 mt-5 mb-8">
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
            className="mb-8"
          >
            <Grid item xs={12} sm={6} md={12}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Product
              </h1>
              <SelectWithTextAndValue
                dataSelect={product}
                state={state}
                setState={setState}
                size="small"
                width="100%"
                name="product"
                menuItemFontSize="text-14"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={12}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Redeem Code
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                minRows={4}
                className="w-full"
                value={state.inputValue}
                name="redeem_code"
                placeholder="Masukan Kode disini"
                variant="outlined"
                onChange={handleChangeInput}
                multiline
                maxRows={5}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={12}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Product
              </h1>
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    size="medium"
                    style={{ fontWeight: 700 }}
                  />
                )}
                onChange={handleChange}
                value={isActive.isActive}
                InputProps={{
                  style: {
                    borderRadius: 5,
                  },
                }}
                size={"md"}
                className="bg-white w-full"
                variant="outlined"
              >
                <MenuItem key={1} value={1} text={"Active"}>
                  Active
                </MenuItem>
                <MenuItem key={2} value={0} text={"NonActive"}>
                  Sold
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <GeneralButton variant="contained" name="Save" data={handleSubmit} />
        </div>
      </Card>
      <Card className="mt-5 py-10 px-10">
        <div className="mx-8 px-10 mt-5 mb-8">
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
            className="mb-8"
          >
            <Grid item xs={12} sm={6} md={12}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Product
              </h1>
              <SelectWithTextAndValue
                dataSelect={product}
                state={state}
                setState={setState}
                size="small"
                width="100%"
                name="productImport"
                menuItemFontSize="text-14"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <div className="mb-5 font-semimedium text-14">
                <h1
                  className="mb-5 font-semimedium text-14"
                  style={{ color: "#0a0a0a" }}
                >
                  Import File
                </h1>
                <span>Format file yang didukung : txt & csv</span>
              </div>
              <div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="fileInput"
                  onChange={handleFileInputChange}
                  accept=".txt, .csv"
                />
                <label htmlFor="fileInput">
                  <OutlinedInput
                    type="text"
                    value={selectedFile ? selectedFile.name : ""}
                    readOnly
                    endAdornment={
                      <InputAdornment position="end">
                        <Button variant="contained" component="span">
                          Browse
                        </Button>
                      </InputAdornment>
                    }
                  />
                </label>

                {selectedFile && (
                  <div>
                    <p>File terpilih: {selectedFile.name}</p>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleFileDelete}
                    >
                      Hapus File
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <GeneralButton
            variant="contained"
            name="Save"
            data={handleSubmitFile}
          />
        </div>
      </Card>
    </div>
  );
};

export default AddGamesVoucher;
