import { Card, Grid, Icon, TextField, Button } from "@material-ui/core";
import React, { useState, useLayoutEffect, useEffect } from "react";
import "../../../styles/css/DetailUser.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useParams, useHistory } from "react-router-dom";
import { UploadImageWithButton } from "../../components";
import {
  editGamesList,
  getAllCategories,
  getDetailGamesList,
} from "../../redux/actions/GamesActions";
import Swal from "sweetalert2";
import GeneralButton from "../../components/buttons/GeneralButton.jsx";
import ListGamesFilter from "./components/ListGamesFilter";
import SelectOfArray from "../../components/select/SelectOfArray";
import SelectWithTextAndValue from "../../components/select/SelectWithTextAndValue";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1253FA",
    },
  },
});

const EditListGames = () => {
  const history = useHistory();
  const { id } = useParams();
  const goToTop = () => {
    global.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [onHoverFieldInfo, setOnHoverFieldInfo] = useState(false);

  useEffect(() => {
    if (onHoverFieldInfo) {
      document.body.style.cursor = "pointer";
    } else {
      document.body.style.cursor = "default";
    }
  }, [onHoverFieldInfo]);

  const handleOnHoverFieldInfo = () => {
    setOnHoverFieldInfo(true);
    console.log("onHoverFieldInfo", onHoverFieldInfo);
  };

  const handleOnLeaveFieldInfo = () => {
    setOnHoverFieldInfo(false);
    console.log("onHoverFieldInfo", onHoverFieldInfo);
  };

  const [state, setState] = useState({
    foto: "",
    foto2: "",
    game_description: "",
    field_description: "",
    field_img: "",
    title: "",
    kode_game: "",
    category_code: "",
    categoryList: [],
  });

  useLayoutEffect(() => {
    getDetailGamesList(id).then((res) => {
      let data = res.data?.data;
      setState((prev) => ({
        ...prev,
        foto: data.img,
        foto2: data.field_img,
        field_img: data.field_img,
        game_description: data.game_description,
        field_description: data.field_description,
        title: data.title,
        kode_game: data.code,
        category_code: data.category?.category_code,
      }));

      setFieldList(data.fields);

      console.log("DGIIIIIIIIIIIIIIIIIIIIIIIIIIIIII ", data.games_item);
      setProductList(data.games_item);
    });

    getAllCategories().then((res) => {
      const data = res?.data?.data;

      setState((prev) => ({
        ...prev,
        categoryList: data.map((item) => ({
          text: item.category_name,
          value: item.category_code,
        })),
      }));
    });
  }, []);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    try {
      fieldList.forEach((obj) => {
        if (!obj.name || !obj.type) {
          throw new Error("data isian Field tidak lengkap");
        }
      });

      productList.forEach((obj, index) => {
        if (!obj.title) {
          throw new Error("Judul untuk produk "+(index+1)+" wajib diisi");
        }
        if (!obj.from) {
          throw new Error("Asal untuk produk "+(index+1)+" wajib diisi");
        }
        if (!obj.price) {
          throw new Error("Harga member untuk produk "+(index+1)+" wajib diisi");
        }
        if (!obj.price_not_member) {
          throw new Error("Harga non member untuk produk "+(index+1)+" wajib diisi");
        }
        if (!obj.price_reseller) {
          throw new Error("Harga reseller untuk produk "+(index+1)+" wajib diisi");
        }
        if ((obj.from == 'unipin' && !obj.denomination_id)) {
          throw new Error("Denomination ID Unipin untuk produk "+(index+1)+" wajib diisi");
        }
        if ((obj.from == 'apigames' && !obj.ag_code)) {
          throw new Error("Apigames code untuk produk "+(index+1)+" wajib diisi");
        }
        if ((obj.from == 'digiflazz' && !obj.digi_code)) {
          throw new Error("Digiflazz code untuk produk "+(index+1)+" wajib diisi");
        }
      });

      if (
        // !state.foto ||
        // !state.foto2 ||
        !state.title ||
        !state.kode_game ||
        !state.game_description ||
        !state.field_description
      ) {
        throw new Error("data isian Game tidak lengkap");
      }

      editGamesList({
        img: state.foto,
        field_img: state.foto2,
        game_description: state.game_description,
        field_description: state.field_description,
        id: state.kode_game,
        title: state.title,
        category_code: state.category_code,
        kode_game: state.kode_game,
        fieldList,
        productList,
      })
        .then((res) => {
          if (res.code == 2 || res.code == 1) {
            throw new Error(res.message);
          }

          let timerInterval;
          Swal.fire({
            title: "Sedang diproses...",
            html: "tunggu dalam waktu <b></b>.",
            timer: 4000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              setTimeout(() => {
                clearInterval(timerInterval);
                history.push("/games/listGames");
                Swal.fire(
                  "Success!",
                  "List Games berhasil diperbarui",
                  "success"
                );
              }, 4000);
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 1000);
            },
          });
        })
        .catch((e) => {
          Swal.fire("Oopss!", e.message, "error");
        });
    } catch (e) {
      Swal.fire("Oopss!", e.message, "error");
    }
  };

  const [fieldList, setFieldList] = useState([
    {
      name: "",
      type: "",
    },
  ]);

  const renderFields = () => {
    const isLastInput = (index) => fieldList.length - 1 !== index;
    const onlyOne = () => fieldList.length == 1;

    return (
      <>
        {fieldList?.map((item, index) => (
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            style={{
              marginBottom: "30px",
            }}
          >
            <Grid item sm={5}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Nama
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                className="w-full"
                value={fieldList[index].name}
                name="name"
                placeholder="Nama"
                variant="outlined"
                onChange={handleFieldChange(index, "name")}
              />
            </Grid>
            <Grid item sm={5}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Tipe
              </h1>
              <SelectOfArray
                dataSelect={[
                  {
                    text: "String",
                    value: "string",
                  },
                  {
                    text: "Number",
                    value: "number",
                  },
                ]}
                state={fieldList}
                setState={setFieldList}
                size="small"
                label="Tipe"
                width="100%"
                name="type"
                index={index}
                menuItemFontSize="text-14"
              />
            </Grid>

            {onlyOne() ? (
              <Grid item className="mt-10" sm={2}>
                <div
                  className="border-radius-circle bg-primary text-white w-35 h-35"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleAddField}
                >
                  <Icon fontSize="medium">add-icon</Icon>
                </div>
              </Grid>
            ) : isLastInput(index) ? (
              <Grid item className="mt-10" sm={2}>
                <div
                  className="border-radius-circle bg-error w-35 h-35"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleRemoveField(index)}
                >
                  <Icon fontSize="medium">delete-outline-icon</Icon>
                </div>
              </Grid>
            ) : (
              <Grid
                item
                className="mt-10"
                sm={2}
                container
                justifyContent="start"
              >
                <Grid item className="mr-2">
                  <div
                    className="border-radius-circle bg-primary w-35 h-35 text-white"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleAddField}
                  >
                    <Icon fontSize="medium">add-icon</Icon>
                  </div>
                </Grid>
                <Grid item>
                  <div
                    className="border-radius-circle bg-error w-35 h-35"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => handleRemoveField(index)}
                  >
                    <Icon fontSize="medium">delete-outline-icon</Icon>
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </>
    );
  };

  const handleFieldChange = (index, name) => (e) => {
    const updatedField = [...fieldList];

    updatedField[index][name] = e.target.value;

    setFieldList(updatedField);
  };

  const handleAddField = () => {
    setFieldList((prev) => [
      ...prev,
      {
        name: "",
      },
    ]);
  };

  const handleRemoveField = (index) => {
    const updatedField = [...fieldList];
    updatedField.splice(index, 1);
    setFieldList(updatedField);
  };

  const [productList, setProductList] = useState([
    {
      code: "",
      title: "",
      isActive: "",
      from: "",
      denomination_id: "",
      price: "",
      price_not_member: "",
      price_reseller: "",
    },
  ]);

  const renderProduct = () => {
    const isLastInput = (index) => productList.length - 1 !== index;
    const onlyOne = () => productList.length == 1;

    return (
      <>
        {productList?.map((item, index) => (
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            style={{
              marginBottom: "100px",
            }}
          >
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item>
                <h3
                  className="text-20 font-medium mb-5"
                  style={{ color: "#0A0A0A" }}
                >
                  Product {index + 1}
                </h3>
              </Grid>
              {onlyOne() ? (
                <Grid item className="mt-5">
                  <Button
                    variant="contained"
                    className="bg-primary text-white border-radius-4 p-2"
                    onClick={() => handleAddProduct()}
                    fullWidth
                  >
                    <Icon className="mr-1" fontSize="medium">
                      add-icon
                    </Icon>
                    Add Product
                  </Button>
                </Grid>
              ) : isLastInput(index) ? (
                <Grid item className="mt-5">
                  <Button
                    variant="outlined"
                    onClick={() => handleRemoveProduct(index)}
                    fullWidth
                    className="border-error border-radius-4 p-2 text-error"
                  >
                    <Icon className="mr-1" fontSize="medium">
                      delete-outline-icon
                    </Icon>
                    Delete
                  </Button>
                </Grid>
              ) : (
                <Grid item container justifyContent="flex-end" spacing={2}>
                  <Grid item className="mt-5">
                    <Button
                      variant="contained"
                      className="bg-primary text-white border-radius-4 p-2"
                      onClick={() => handleAddProduct()}
                      fullWidth
                    >
                      <Icon className="mr-1" fontSize="medium">
                        add-icon
                      </Icon>
                      Add Product
                    </Button>
                  </Grid>
                  <Grid item className="mt-5">
                    <Button
                      variant="outlined"
                      onClick={() => handleRemoveProduct(index)}
                      fullWidth
                      className="border-error border-radius-4 p-2 text-error"
                    >
                      <Icon className="mr-1" fontSize="medium">
                        delete-outline-icon
                      </Icon>
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Nama
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                className="w-full"
                value={productList[index].title}
                name="title"
                placeholder="Nama"
                variant="outlined"
                onChange={handleProductChange(index, "title")}
              />
            </Grid>
            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Status Produk
              </h1>
              <SelectOfArray
                dataSelect={[
                  {
                    text: "Aktif",
                    value: "1",
                  },
                  {
                    text: "Tidak Aktif",
                    value: "0",
                  },
                ]}
                state={productList}
                setState={setProductList}
                size="small"
                label="Status Produk"
                width="100%"
                name="isActive"
                index={index}
                menuItemFontSize="text-14"
              />
            </Grid>
            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Asal Produk
              </h1>
              <SelectOfArray
                dataSelect={[
                  {
                    text: "Unipin",
                    value: "unipin",
                  },
                  {
                    text: "Apigames",
                    value: "apigames",
                  },
                  {
                    text: "Digiflazz",
                    value: "digiflazz",
                  },
                ]}
                state={productList}
                setState={setProductList}
                size="small"
                label="Asal Produk"
                width="100%"
                name="from"
                index={index}
                menuItemFontSize="text-14"
              />
            </Grid>

            <Grid item sm={6}>
              {productList[index]?.from?.toLowerCase() === "unipin" ? (
                <>
                  <h1
                    className="mb-5 font-semimedium text-14"
                    style={{ color: "#0a0a0a" }}
                  >
                    Denomination ID
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: 5,
                        minHeight: 46,
                      },
                    }}
                    value={productList[index].denomination_id}
                    name="denomination_id"
                    placeholder="Denomination ID"
                    className="w-full"
                    variant="outlined"
                    onChange={handleProductChange(index, "denomination_id")}
                  />
                </>
              ) : productList[index]?.from?.toLowerCase() === "apigames" ? (
                <>
                  <h1
                    className="mb-5 font-semimedium text-14"
                    style={{ color: "#0a0a0a" }}
                  >
                    Apigames Code
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: 5,
                        minHeight: 46,
                      },
                    }}
                    value={productList[index].ag_code}
                    name="ag_code"
                    placeholder="Apigames Code"
                    className="w-full"
                    variant="outlined"
                    onChange={handleProductChange(index, "ag_code")}
                  />
                </>
              ) : productList[index]?.from?.toLowerCase() === "digiflazz" ? (
                <>
                  <h1
                    className="mb-5 font-semimedium text-14"
                    style={{ color: "#0a0a0a" }}
                  >
                    Digiflazz Code
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: 5,
                        minHeight: 46,
                      },
                    }}
                    value={productList[index].digi_code}
                    name="digi_code"
                    placeholder="Digiflazz Code"
                    className="w-full"
                    variant="outlined"
                    onChange={handleProductChange(index, "digi_code")}
                  />
                </>
              ) : (
                ""
              )}
            </Grid>
            {/* {productList[index]?.from?.toLowerCase() === "unipin" ? (
              <Grid item sm={6}>
                <h1
                  className="mb-5 font-semimedium text-14"
                  style={{ color: "#0a0a0a" }}
                >
                  Denomination ID
                </h1>
                <TextField
                  required={true}
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: 5,
                      minHeight: 46,
                    },
                  }}
                  className="w-full"
                  value={productList[index].denomination_id}
                  name="denomination_id"
                  placeholder="Denomination ID"
                  variant="outlined"
                  onChange={handleProductChange(index, "denomination_id")}
                />
              </Grid>
            ) : (
              <Grid item sm={6}></Grid>
            )} */}

            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Harga Member
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                className="w-full"
                value={productList[index].price}
                name="price"
                placeholder="Harga Member"
                variant="outlined"
                onChange={handleProductChange(index, "price")}
              />
            </Grid>
            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Harga Non Member
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                className="w-full"
                value={productList[index].price_not_member}
                name="price_not_member"
                placeholder="Harga Non Member"
                variant="outlined"
                onChange={handleProductChange(index, "price_not_member")}
              />
            </Grid>
            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Harga Reseller
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                className="w-full"
                value={productList[index].price_reseller}
                name="price_reseller"
                placeholder="Harga Reseller"
                variant="outlined"
                onChange={handleProductChange(index, "price_reseller")}
              />
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  const handleProductChange = (index, name) => (e) => {
    const updatedField = [...productList];

    updatedField[index][name] = e.target.value;

    setProductList(updatedField);
  };

  const handleAddProduct = () => {
    setProductList((prev) => [
      ...prev,
      {
        title: "",
      },
    ]);
  };

  const handleRemoveProduct = (index) => {
    const updatedField = [...productList];
    updatedField.splice(index, 1);
    setProductList(updatedField);
  };

  const handleChangePhoto = (foto, path, id) => {
    setState((prev) => ({
      ...prev,
      foto,
      path,
    }));
  };

  const handleChangePhoto2 = (foto2, path2, id) => {
    setState((prev) => ({
      ...prev,
      foto2,
      path2,
    }));
  };

  return (
    <div className="analytics m-sm-30 mt-7 text-black">
      <h1 className="fw-600 m-0">Edit Games</h1>
      <Grid
        item
        xs={12}
        sm
        className="d-flex mr-8"
        style={{ justifyContent: "flex-end" }}
      >
        <ThemeProvider theme={theme}>
          <GeneralButton variant="contained" name="Save" data={handleSubmit} />
        </ThemeProvider>
      </Grid>
      <Card className="mt-5 py-10 px-10">
        <div className="mx-8 mt-5 mb-8">
          <Grid item xs={12} sm={6}>
            <h3
              className="text-20 font-medium mb-5"
              style={{ color: "#0A0A0A" }}
            >
              Games
            </h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <h1
              className="font-semimedium text-14"
              style={{ color: "#0a0a0a" }}
            >
              Unggah Foto
            </h1>
            <UploadImageWithButton
              uploadFoto={handleChangePhoto}
              preview={state.foto}
              formatIcon={false}
              state={{ index: 5, id: 5 }}
              autoCall={false}
              handleDelete={() => {
                setState((prev) => ({
                  ...prev,
                  foto: "",
                }));
              }}
            />
          </Grid>
          <Grid container className="mt-4" spacing={2}>
            <Grid item xs={12} sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Game
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                className="w-full"
                value={state.title}
                name="title"
                placeholder="Games"
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Kategori
              </h1>
              <SelectWithTextAndValue
                dataSelect={state.categoryList}
                state={state}
                required={false}
                setState={setState}
                size="small"
                label="Kategori"
                width="100%"
                name="category_code"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Kode Game
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                className="w-full"
                value={state.kode_game}
                name="kode_game"
                placeholder="Kode Game"
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Field Deskripsi
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                className="w-full"
                value={state.field_description}
                name="field_description"
                placeholder="Field Deskripsi"
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h1
                className="my-2 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Game Deskripsi
              </h1>
              <TextField
                required={true}
                size="small"
                multiline
                rows={10}
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                className="w-full mt-2"
                value={state.game_description}
                name="game_description"
                placeholder="Game Deskripsi"
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h1
                className="font-semimedium text-14 mb-4"
                style={{ color: "#0a0a0a" }}
              >
                Foto
                <span
                  className="mx-2 border-radius-circle bg-primary w-20 h-20 text-white"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "1rem",
                    padding: "0 0 2px 0.5px",
                  }}
                  onMouseEnter={handleOnHoverFieldInfo}
                  onMouseLeave={handleOnLeaveFieldInfo}
                >
                  <span onClick={handleAddField}>
                    <QuestionMarkIcon
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      add-icon
                    </QuestionMarkIcon>
                  </span>
                </span>
                {onHoverFieldInfo ? (
                  <div className="img-helper">
                    <div
                      id="img-helper-element"
                      style={{
                        position: "absolute",
                        zIndex: "999",
                        left: "40%",
                      }}
                    >
                      <img
                        src="https://vogaon.com/wp-content/plugins/woo-unipin/unipin/assets/img/default-guide.png"
                        style={{ position: "relative", zIndex: "1000" }}
                      />
                    </div>
                  </div>
                ) : null}
              </h1>

              <UploadImageWithButton
                uploadFoto={handleChangePhoto2}
                preview={state.foto2}
                formatIcon={false}
                state={{ index: 5, id: 5 }}
                autoCall={false}
                handleDelete={() => {
                  setState((prev) => ({
                    ...prev,
                    foto2: "",
                  }));
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Card>

      {/* FIELDS */}
      <Card className="mt-5 py-10 px-10">
        <div className="mx-8 mt-5 mb-8">
          <Grid item xs={12} sm={6}>
            <h3
              className="text-20 font-medium mb-5"
              style={{ color: "#0A0A0A" }}
            >
              Fields
            </h3>
          </Grid>
          {renderFields()}
        </div>
      </Card>

      {/* PRODUK */}
      <Card className="mt-5 py-10 px-10">
        <div className="mx-8 mt-5 mb-8">{renderProduct()}</div>
        {/* <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <button
              className="mx-8 mb-8 border-radius-circle bg-primary text-white w-35 h-35"
              style={{ marginTop: "25px", padding: "8.7px" }}
              onClick={goToTop}
            >
              <Icon className="mr-1" fontSize="medium">
                <KeyboardArrowUpIcon />
              </Icon>
            </button>
          </Grid>
        </Grid> */}
      </Card>
    </div>
  );
};

export default EditListGames;
