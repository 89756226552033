import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, InputAdornment, Icon, Card } from "@material-ui/core";
import { getApiDocs } from "../../../redux/actions/Settings";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import TableApiDocumentation from "./components/TableApiDocumentation";

const ApiDocumentation = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const { dataApiDocs } = useSelector((state) => state.apiDocs);

    const getData = () => {
        const params = `?search=`
        dispatch(getApiDocs(params))
    }

    useEffect(() => { getData() }, [dispatch])

    const handleChange = (e) => {
        let data = e.target.value;
        setSearch(data);
        if (!data) {
            getData()
        }
    }

    const handleKeyDown = (e) => {
        const params = `?search=${search}`;

        if (e.keyCode === 13) {
            dispatch(getApiDocs(params))
        }
    };

    return (
        <div className="m-sm-30">
            <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                className="my-4 d-flex items-center"
            >
                <Grid item xs={12} sm>
                    <h1 className="text-black font-semibold text-25 my-auto">
                        Dokumentasi API
                    </h1>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={3}
                    sm
                    className="d-flex justify-end"
                >
                    <Link to="/settings/apidocs/add">
                        <Button
                            variant="contained"
                            color="primary"
                            className="px-5 py-2 text-white fw-500"
                            startIcon={<AddIcon />}
                            size="large"
                        >
                            Add
                        </Button>
                    </Link>
                </Grid>
            </Grid>
            <div
                className="mt-2 d-flex items-center"
                style={{ justifyContent: "flex-start" }}
            >
                <TextField
                    size="medium"
                    variant="outlined"
                    // className="w-250"
                    style={{ width: "220px" }}
                    placeholder="Cari Dokumentasi API"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    value={search}
                    name="search"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon>search</Icon>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className="my-8">
                <Card className="px-5">
                    <TableApiDocumentation data={dataApiDocs} getData={getData} />
                </Card>
            </div>
        </div>
    );
};

export default ApiDocumentation;
