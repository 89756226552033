import React from "react";
import { Card, Dialog, Grid, Button, Backdrop, Fade, Typography } from "@material-ui/core";

const ModalDisplayResponse = ({ data, handleClose, open }) => {
    return (
        <Dialog
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            open={open}
            onClose={handleClose} scroll="body" size="xl">
            <Card className="p-5">
                <Grid container spacing={4}>
                    <Grid item xs={12} className="text-17 text-black fw-600">
                        Respon API
                    </Grid>
                    <Grid item xs={12}>
                        <Card className="px-5" style={{ backgroundColor: "#f5f5f5" }}>
                            <Fade in={open}>
                                <div className="modal-container">
                                    <div className="modal-content m-5">
                                        <pre>{JSON.stringify(data?.response_api, null, 2)}</pre>
                                    </div>
                                </div>
                            </Fade>
                        </Card>
                    </Grid>
                    <Grid item xs={12} className="text-17 text-black fw-600">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClose()}
                        >
                            Tutup
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Dialog>
    );
};

export default ModalDisplayResponse;