import React from "react";
import { useDispatch, useSelector } from "react-redux";

import PaymentMethodCard from "./PaymentMethodCard";
import {
  changeOrderPaymentMethod,
  getPaymentMethod,
} from "../../redux/actions/Payment/PaymentMethodActions";
import { getPaymentGateway } from "../../redux/actions/Payment/PaymentGatewayActions";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { useEffect } from "react";
import { useState } from "react";
import GeneralButton from "../../components/buttons/GeneralButton";
import { Save } from "@material-ui/icons";
import Swal from "sweetalert2";

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const { dataPaymentMethod, dataPaymentGateway } = useSelector(
    (state) => state.payment
  );
  const [paymentMethod, setPaymenMethod] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabledSave, setdisabledSave] = useState(true);
  const [disableDrag, setDisableDrag] = useState(false);

  const getData = () => {
    dispatch(getPaymentMethod());
  };

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (
      sourceIndex >= paymentMethod.length ||
      targetIndex >= paymentMethod.length
    ) {
      return;
    }
    const result = swap(paymentMethod, sourceIndex, targetIndex);

    setdisabledSave(false);

    return setPaymenMethod([...result]);
  }

  const saveOrdering = () => {
    console.log(paymentMethod);
    changeOrderPaymentMethod({data: paymentMethod})
      .then((res) => {
        Swal.fire({
          title: "Berhasil",
          text: "Pengurutan Disimpan !",
          imageUrl: `/assets/images/icons/ic_success.svg`,
          imageWidth: 92,
          imageHeight: 92,
          confirmButtonText: "Ok",
          confirmButtonColor: "#2560e5",
        });

        setdisabledSave(true);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Oops",
          text: "Gagal disimpan, coba beberapa saat lagi !",
          imageUrl: `/assets/images/icons/ic_error.svg`,
          imageWidth: 92,
          imageHeight: 92,
          confirmButtonText: "Ok",
          confirmButtonColor: "#2560e5",
        });
      });
  };

  React.useLayoutEffect(() => {
    dispatch(getPaymentGateway(""));
    getData();
  }, []);

  useEffect(() => {
    if (dataPaymentMethod.length > 0) {
      setPaymenMethod(dataPaymentMethod);
      setLoading(false);
    }
  }, [dataPaymentMethod]);

  return (
    <div className="m-3">
      <div className="flex justify-content-between mr-4">
        <h1 className="text-black fw-600 text-25 my-4 ml-5">Payment Method</h1>
        <GeneralButton
          name="Save"
          icon={<Save />}
          variant="contained"
          disabled={disabledSave}
          data={saveOrdering}
        />
      </div>
      <GridContextProvider onChange={onChange}>
        <div className="container">
          <GridDropZone
            className="dropzone left"
            id="left"
            boxesPerRow={3}
            rowHeight={150}
            disableDrag={disableDrag}
          >
            {paymentMethod.map((item) => (
              <GridItem key={item.pm_code}>
                <div className="grid-item">
                  <PaymentMethodCard
                    isThereContent
                    data={item}
                    getData={getData}
                    dataPaymentGateway={dataPaymentGateway}
                    setDisableDrag={setDisableDrag}
                    disableDrag={disableDrag}
                  />
                </div>
              </GridItem>
            ))}
            <GridItem key={"new"} id="new">
              <div className="grid-item">
                <PaymentMethodCard
                  getData={getData}
                  dataPaymentGateway={dataPaymentGateway}
                  setDisableDrag={setDisableDrag}
                  disableDrag={disableDrag}
                />
              </div>
            </GridItem>
          </GridDropZone>
        </div>
      </GridContextProvider>
    </div>
  );
};

export default PaymentMethod;
