import React, { useState, useLayoutEffect } from "react";

import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import SimpleCard from "../../assets/components/cards/SimpleCard";
import TableGamesCategory from "./components/TableGamesCategory";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import GeneralButton from "../../components/buttons/GeneralButton.jsx";
import { getAllCategories, getAllGamesVoucher, getListGameItems } from "../../redux/actions/GamesActions";
import { Autocomplete } from "@material-ui/lab";
import ListGamesItemFilter from "./components/ListGamesItemFilter";
import ListGamesFilter from "./components/ListGamesFilter";
import StatusFilter from "./components/statusFilter";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1253FA",
    },
  },
});

const GamesVoucher = () => {
  const [search, setSearch] = useState("");
  const [state, setState] = useState({
    categories: [],
  });
  const [categories, setCategories] = useState([]);
  const [gamesItem, setGamesItem] = useState("");
  const [games, setGames] = useState("");
  const [status, setStatus] = useState("");

  const getDataVoucher = () => {
    const params = `?search=${search}`;
    getAllGamesVoucher(params).then((res) => {
      const data = res.data?.data;
      setState((prev) => ({
        ...prev,
        categories: data,
      }));
      setFilteredData(data);
    });
  };

  const [filteredData, setFilteredData] = useState();

  const handleFilterStatus = (e) => {
    const data = e.target.value;
    setStatus(data);
    filterArrayStatus(data);
  };

  const handleFilterItem = (e) => {
    const data = e.target.value;
    setGamesItem(data);
    filterArray(data);
  };

  const handleFilter = (e) => {
    const data = e.target.value;
    setGames(data);
    filterGames(data);
  };

  const handlerSearchText = (e) => {
    const data = e.target.value;
    setSearch(data);
    filterText(data);
  };

  const filterArrayStatus = (searchTerm) => {
    const filteredArray = state.categories.filter((item) =>
      item.voucher_status.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredArray);
  };

  const filterText = (searchTerm) => {
    const filteredArray = state.categories.filter((item) =>
      item.redeem_code.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredArray);
  };

  const filterGames = (searchTerm) => {
    const filteredArray = state.categories.filter((item) =>
      item.game_code.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredArray);
  };

   const filterArray = (searchTerm) => {
    console.log('ST',searchTerm);
     const filteredArray = state.categories.filter((item) =>
       item.games_item_code.toLowerCase().includes(searchTerm.toLowerCase())
     );
     setFilteredData(filteredArray);
   };

  useLayoutEffect(() => {
    getDataVoucher();
    // getDataCategories();
  }, []);

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        className="my-4 d-flex items-center"
      >
        <Grid item xs={12} sm>
          <h1 className="fw-600 m-0">Games Voucher</h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: "flex-end" }}
        >
          <Link to="/games/voucher/add">
            <ThemeProvider theme={theme}>
              <GeneralButton
                name="Add"
                icon={<AddIcon />}
                variant="contained"
              />
            </ThemeProvider>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div
          className="mt-2 mb-7 d-flex items-center"
          style={{ justifyContent: "flex-end" }}
        >
          <TextField
            size="small"
            variant="outlined"
            className="w-250"
            placeholder="Cari Redeem Code"
            onChange={handlerSearchText}
            value={search}
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
          />
          <div className="ml-3">
            <StatusFilter
              value={status}
              label="Status"
              name="status"
              width="240px"
              handleChange={handleFilterStatus}
              search
            />
          </div>
          <div className="ml-3">
            <ListGamesFilter
              value={games}
              label="Games"
              name="games"
              width="240px"
              handleChange={handleFilter}
              search
            />
          </div>
          <div className="ml-3">
            <ListGamesItemFilter
              value={gamesItem}
              label="Kategori Voucher"
              name="kategori"
              width="240px"
              handleChange={handleFilterItem}
              search
            />
          </div>
        </div>
        <TableGamesCategory
          voucher={true}
          data={filteredData}
          getData={getDataVoucher}
          checklist={true}
        />
      </SimpleCard>
    </div>
  );
};

export default GamesVoucher;
