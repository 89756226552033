import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Card, Dialog, Grid, Button, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";

import UploadImageWithButton from "../../components/inputs/UploadImageWithButton";
import Swal from "sweetalert2";
import { RichTextEditor } from "../../../matx";
import { useEffect } from "react";
import { addSlider, updateSlider } from "../../redux/actions/SlidersAction";

const ModalAdd = ({ data = {}, getData, open = false, handleClose, type = 'add' }) => {
  const dispatch = useDispatch();
  const useStyles = makeStyles({
    dialog: {
      // height: 'fit-content',
      scrollbarColor: "transparent",
      scrollbarWidth: "0px",
      minWidth: "800px",
      maxWidth: "1200px,",
      // overflow: 'hidden',
    },
    backDrop: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  });
  const classes = useStyles();

  const [state, setState] = React.useState({
    image_preview: data.image || "",
    image: "",
    id: data.id || "",
    link: data.link || "",
    meta_desc: data.meta_desc || "",
  });

  const handleChangePhoto = (image, path, id) => {
    console.log(image, path);
    setState((prev) => ({
      ...prev,
      image_preview: path,
      image,
    }));
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    let obj = {
      link: state.link,
      id: state.id,
      image: state.image,
      meta_desc: state.meta_desc,
    };
    try {
      if (state.link == "" || (state.image == "" && type == 'add') || state.meta_desc == "") {
        Swal.fire("Gagal!", "Harap isi semua inputan !", "warning");
        return;
      }
      if (type === "add") {
        addSlider(obj)
          .then((res) => {
            setState((prev) => ({
              image: "",
              id: "",
              link: "",
              meta_desc: "",
            }));
            getData();
            handleClose();
            Swal.fire("Berhasil!", "Data Banner berhasil disimpan", "success");
          })
          .catch((err) => {
            Swal.fire("Gagal!", "System Under Maintenance !", "error");
          });
      } else {
        updateSlider(state.id, obj)
          .then((res) => {
            setState((prev) => ({
              ...prev,
              content: false,
            }));
            getData();
            handleClose();
            Swal.fire("Berhasil!", "Data Banner berhasil disimpan", "success");
          })
          .catch((err) => {
            Swal.fire("Gagal!", "System Under Maintenance !", "error");
          });
      }
    } catch (e) {
      Swal.fire("Gagal!", "Data Banner gagal disimpan", "error");
    }
  };

  const handleChangeContent = (e, name) => {
    setState((prev) => ({
      ...prev,
      [name]: e,
    }));
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      open={open}
      onClose={() =>
        setState((prev) => ({
          ...prev,
          noContent: false,
        }))
      }
      scroll="body"
    >
      <Card className="p-5">
        <Grid container spacing={4}>
          <Grid item xs={12} className="text-17 text-black fw-600">
            Add Banner
          </Grid>
          <Grid item xs={12} md={6}>
            <h1 className="mb-5 fw-500 text-13 text-black">Unggah Banner</h1>
            <UploadImageWithButton
              minHeight="13.5rem"
              maxHeight="14rem"
              uploadFoto={handleChangePhoto}
              preview={state.image_preview}
              formatIcon={false}
              state={{ index: 5, id: 5 }}
              getData={getData}
              autoCall={false}
              handleDelete={() => {
                setState((prev) => ({
                  ...prev,
                  image: "",
                }));
              }}
              hideDelete={true}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <h1 className="mb-5 fw-500 text-13 text-black">Link</h1>
            <TextField
              InputProps={{
                style: {
                  borderRadius: 5,
                  minHeight: 46,
                },
              }}
              required={true}
              size="small"
              name="link"
              value={state.link || ""}
              onChange={handleChange}
              className={`w-full`}
              placeholder="https://vogaon.com/..."
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <h3 className="mb-5 fw-500 text-15 text-black">Meta Description</h3>
            <RichTextEditor
              content={state?.meta_desc || ""}
              placeholder=""
              handleContentChange={(e) => handleChangeContent(e, "meta_desc")}
            />
          </Grid>
        </Grid>
        <div className="d-flex items-center justify-start gap-11 mt-20">
          <Button
            variant="outlined"
            color="primary"
            className="w-140 py-2 px-30 text-14 border-radius-4 text-center fw-500"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="w-140 border-radius-4 py-2 px-30 text-14 text-center fw-500 text-white"
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </div>
      </Card>
    </Dialog>
  );
};

export default ModalAdd;
