import { useState } from "react";
import React from "react";
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Checkbox,
  Button,
  MenuItem,
  Select,
} from "@material-ui/core";
import { formatDate } from "../../../utlis/formatDate";
import Swal from "sweetalert2";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { CheckBox } from "@material-ui/icons";
import { updateBulkStatusTopup } from "../../redux/actions/Transaction/TransactionActions";

const RenderTable = ({
  data,
  state,
  search,
  getData,
  customColumns,
  TotalColspan = 18,
  aksiSpan = 3,
  id,
  setState,
  checkedData,
}) => {
  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 20) {
      return state.page * 20;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  const handleCheckboxChange = (event) => {
    console.log(event.target);
    // checked or not
    const checked = event.target.checked;
    // get transaction_code
    const transaction_code = event.target.name;
    // console.log("target: ", target);
    if (checked === true) {
      setState((prevState) => ({
        ...prevState,
        data_checked: [...prevState.data_checked, transaction_code],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data_checked: prevState.data_checked.filter(
          (item) => item !== transaction_code
        ),
      }));
    }
  };

  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) => (
        <TableRow
          hover
          key={index}
          style={{
            borderTop: "1px #e6e5e5 solid",
            marginTop: "20px",
          }}
        >
          <TableCell
            className="text-14 pl-1 "
            align="center"
            style={{ color: "#0A0A0A" }}
            colSpan={1}
          >
            <Checkbox
              color="primary"
              onChange={handleCheckboxChange}
              name={item.transaction_code}
              checked={
                checkedData.filter((dt) => dt === item.transaction_code)
                  .length > 0
                  ? true
                  : false
              }
            />
          </TableCell>
          <TableCell
            className="text-14 pl-2 "
            align="center"
            style={{ color: "#0A0A0A" }}
            colSpan={1}
          >
            {index + 1 + handleNumbering()}
          </TableCell>
          {customColumns?.map((column, index) => (
            <TableCell
              key={index}
              className="text-14 pl-2"
              // style={{ color: "#0A0A0A" }}
              style={
                column.type === "topup" && item[column.key] > 0
                  ? { color: "#51AF77" }
                  : column.type === "topup" && item[column.key] < 0
                  ? { color: "#D55454" }
                  : column.type === "status" && item[column.key] == "processing"
                  ? { color: "#1253FA" }
                  : (column.type === "status" &&
                      item[column.key] == "waiting") ||
                    (column.type === "status" && item[column.key] == "pending")
                  ? { color: "#DF8838" }
                  : column.type === "status" && item[column.key] == "success"
                  ? { color: "#51AF77" }
                  : { color: "#0A0A0A" }
              }
              colSpan={column.colSpan}
              align={column.align}
            >
              {column.type === "price" ||
              (column.type === "topup" && item[column.key])
                ? `Rp ${item[column.key]
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}`
                : column.type === "date" && item[column.key]
                ? formatDate(item[column.key])
                : column.type !== "pembelian"
                ? item[column.key]
                : null}
            </TableCell>
          ))}
        </TableRow>
      ))
  ) : (
    <>
      <TableRow
        hover
        style={{
          borderTop: "1px #e6e5e5 solid",
          marginTop: "20px",
        }}
      >
        <TableCell colSpan={TotalColspan} align="center">
          Data kosong
        </TableCell>
      </TableRow>
    </>
  );
};

const TableDetailTopup = ({
  search,
  data = [],
  getData,
  tableHeadItems,
  customColumns,
  TotalColspan,
  aksiSpan,
  id,
}) => {
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 20,
    data_checked: [],
    allChecked: false,
    status: "",
  });

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };
  const tableHead = tableHeadItems?.map((item, index) => (
    <TableCell key={index} align={item.align} colSpan={item.colSpan}>
      <div className="table-head-font"> {item.name}</div>
    </TableCell>
  ));

  const handleCheckboxChange = (event) => {
    // checked or not
    const checked = event.target.checked;
    if (checked === true) {
      var start = state.page;
      var end = (state.page + 1) * state.rowsPerPage;
      if (state.page > 0) {
        start = state.rowsPerPage + 1;
      }
      console.log(start, end);
      setState((prevState) => ({
        ...prevState,
        data_checked: data
          .slice(start, end)
          .map((item) => item.transaction_code),
        allChecked: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data_checked: [],
        allChecked: false,
      }));
    }
  };

  const handleChange = ({ target: { value, name } }) => {
    setState((pref) => ({
      ...pref,
      status: value,
    }));
  };

  const updateBulkStatus = () => {
    const params = {
      status: state.status,
      data: state.data_checked,
    };
    updateBulkStatusTopup(params)
      .then((res) => {
        Swal.fire({
          title: "Berhasil",
          text: "Data berhasil diupdate",
          timer: 2000,
          icon: "success",
        });
        setState((prevState) => ({
          ...prevState,
          data_checked: [],
          allChecked: false,
        }));
        getData();
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire({
          title: "gagal",
          text: "Data Gagal Diupdate",
          timer: 2000,
          icon: "error",
        });
      });
  };

  return (
    <div className="w-full overflow-auto bg-white izincuti-tabs-slide">
      <Table
        style={{
          borderTop: "1px #e6e5e5 solid",
          marginTop: "20px",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              colSpan={1}
              className="font-medium text-15 pl-1"
              style={{ color: "#0a0a0a" }}
              align="center"
            >
              <Checkbox
                color="primary"
                onChange={handleCheckboxChange}
                checked={state.allChecked}
              />
            </TableCell>
            {tableHead}
          </TableRow>
        </TableHead>

        <TableBody>
          <RenderTable
            data={data}
            state={state}
            getData={getData}
            search={search}
            customColumns={customColumns}
            aksiSpan={aksiSpan}
            id={id}
            setState={setState}
            checkedData={state.data_checked}
          />
        </TableBody>
      </Table>
      <div className="d-flex justify-content-between align-content-center align-items-center">
        <div className="d-flex align-content-center align-items-center">
          <div className="d-flex align-content-center align-items-center">
            <CheckBox className="ml-5" htmlColor="#BABEC1" />
            <p className="p-0 m-0" style={{ color: "#BABEC1" }}>
              {state?.data_checked?.length}/{data.length} Data
            </p>
          </div>
          {state?.data_checked?.length > 0 ? (
            <div className="d-flex align-content-center align-items-center ml-5">
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    size="medium"
                    style={{ fontWeight: 700 }}
                  />
                )}
                onChange={handleChange}
                value={state.status}
                style={{ borderRadius: 5, maxHeight: 35 }}
                size={"sm"}
                className="bg-white p-0"
                variant="outlined"
                displayEmpty
              >
                <MenuItem key={0} value={""} text={"Status"}>
                  Status
                </MenuItem>
                <MenuItem key={1} value={"success"} text={"Success"}>
                  Success
                </MenuItem>
                <MenuItem key={3} value={"waiting"} text={"Waiting"}>
                  Waiting
                </MenuItem>
                <MenuItem key={4} value={"pending"} text={"Pending"}>
                  Pending
                </MenuItem>
                <MenuItem key={5} value={"cancel"} text={"Cancel"}>
                  Cancel
                </MenuItem>
              </Select>
              {state.status !== "" ? (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className="text-white w-full text-capitalize pc-2 ml-3"
                  disableElevation
                  style={{
                    borderRadius: 5,
                  }}
                  onClick={updateBulkStatus}
                >
                  Save
                </Button>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <TablePagination
        className="px-16"
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={data?.length ? data?.length : 0}
        rowsPerPage={state.rowsPerPage}
        labelRowsPerPage={"From"}
        page={state.page}
        backIconButtonProps={{
          "aria-label": "Previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next page",
        }}
        backIconButtonText="Previous page"
        nextIconButtonText="Next page"
        onChangePage={handleChangePage}
        onChangeRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};

export default TableDetailTopup;
