import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Card, Icon } from "@material-ui/core";
import Swal from "sweetalert2";
import { editApiDocsResponse } from "../../../../redux/actions/Settings/ApiDocs";
import SelectValue from "../components/SelectValue";
import { getApiDocs } from "../../../../redux/actions/Settings";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, Link } from 'react-router-dom';
const EditResponsesApi = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const locationState = location.state;
    const { dataApiDocs } = useSelector((state) => state.apiDocs);

    const getData = () => {
        const params = `?search=`
        dispatch(getApiDocs(params))
    }

    useEffect(() => { getData() }, [dispatch])

    const [state, setState] = useState({
        response_api: JSON.stringify(locationState.response_api) || "",
        response_type: locationState.response_type || "",
    });

    const handleApiDocsChange = (newValue) => {
        setState((prev) => ({
            ...prev,
            api_docs_code: newValue.api_docs_code
        }));
    };

    const handleRequiredChange = (newValue) => {
        setState((prev) => ({
            ...prev,
            response_type: newValue.response_type
        }));
    };

    const apiDocs = dataApiDocs.map((item) => ({
        value: item.api_docs_code,
        text: item.route_name,
    }))

    const required = [
        { value: "success", text: "Success" },
        { value: "error", text: "Error" }
    ]

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = () => {
        try {
            const parsedResponseApi = JSON.parse(state.response_api);

            const requestBody = {
                response_api: parsedResponseApi,
                response_type: state.response_type,
                api_docs_code: state.api_docs_code,
            };

            editApiDocsResponse(locationState.response_code, requestBody)
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Respon API berhasil diperbarui!",
                        showConfirmButton: true,
                        timer: 2000,
                    });

                    history.push({ pathname: "/settings/apidocs/responses", state: locationState })
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: "Gagal menyimpan respon API!",
                        showConfirmButton: true,
                        timer: 2000,
                    });
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Gagal",
                text: "Masukkan input JSON yang valid!",
                showConfirmButton: true,
                timer: 2000,
            });
        }
    };
    // console.log('data', locationState)

    return (
        <div className="m-sm-30">
            {/* Head */}
            <Grid
                container
                spacing={1}
                className="d-flex items-center"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={12} lg={9} sm>
                    <h2 className="fw-600 text-25 text-black">Edit Respon</h2>
                </Grid>
                <Grid item xs={12} lg={3} sm className="justify-end">
                    <Grid container spacing={2}>
                        <Grid item lg={6} xl={6}>
                            <Link to={{
                                pathname: "/settings/apidocs/responses",
                                state: locationState,
                            }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="px-5 py-2 border-2 text-primary fw-500"
                                >
                                    <span className="">Kembali</span>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item lg={6} xl={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="px-5 py-2 border-2 text-white fw-500"
                                onClick={handleSubmit}
                            >
                                <span className="">Simpan</span>
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <div className="my-8">
                <Card className="p-5">
                    {/* Menu */}
                    <div className="p-10">
                        <Grid
                            container
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-end"
                        >
                            <Grid item xs={12} sm={12}>
                                <h3 className="mb-5 fw-500 text-15 text-black">Response API &#40;JSON&#41;</h3>
                                <TextField
                                    multiline
                                    size="small"
                                    name="response_api"
                                    className={`w-full`}
                                    placeholder="Masukan JSON respon disini"
                                    variant="outlined"
                                    value={state.hasOwnProperty(`response_api`) ? state['response_api'] : ""}
                                    onChange={handleChange}
                                    InputProps={{
                                        style: {
                                            borderRadius: 5,
                                            minHeight: 150,
                                            backgroundColor: "white",
                                        },
                                        inputProps: {
                                            style: {
                                                padding: "8px 12px",
                                                whiteSpace: "pre-wrap"
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h3 className="mb-5 fw-500 text-15 text-black">Tipe Respon</h3>
                                <SelectValue state={state.response_type} name="response_type" setState={handleRequiredChange} dataSelect={required} required={true} label="Pilih value" width="100%" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h3 className="mb-5 fw-500 text-15 text-black">API Dokumentasi</h3>
                                <SelectValue state={locationState.api_docs_code} name="api_docs_code" setState={handleApiDocsChange} dataSelect={apiDocs} disabled={true} width="100%" />
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default EditResponsesApi;
