export const formatDate = (date = "") => {
  var d = new Date(date);
  if (date == "") {
    d = new Date();
  }
  var month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minute = d.getMinutes(),
    second = d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  const newDate = [year, month, day].join("-");
  const newTime = [hour, minute, second].join(":");

  return [newDate, newTime].join(" ");
};
