import {
  TextField,
  Icon,
  InputAdornment,
  Grid,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import SimpleCard from "../../assets/components/cards/SimpleCard";
import TableGamesListGames from "./components/TableGamesListGames";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import Button from "@mui/material/Button";
import ListGamesFilter from "./components/ListGamesFilter";
import GeneralButton from "./../../components/buttons/GeneralButton.jsx";
import {
  getAllGamesList,
  getAllCategories,
  syncGameUnipin,
} from "../../redux/actions/GamesActions";
import React, { useState, useLayoutEffect, useEffect } from "react";
import ListItemGameFilter from "./components/ListItemGameFilter";
import { CloudSync } from "@mui/icons-material";
import { ModalSyncProduct } from "../../components";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1253FA",
    },
  },
});

const GamesListGames = () => {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");

  const [state, setState] = useState({
    listGames: [],
    categories_filter: [],
  });
  const [syncOpen, setSyncOpen] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [dataGame, setDataGame] = useState([]);

  const handleClose = (event, reason) => {
    if (reason == "backdropClick" || reason == "escapeKeyDown") return;

    getData();
    setSyncOpen(false);
  };

  const getData = () => {
    const params = `?search=${search}&category_code=${filter}`;

    getAllGamesList(params).then((res) => {
      const data = res.data?.data;
      setState((prev) => ({
        ...prev,
        listGames: data,
      }));
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useLayoutEffect(() => {
    getData();
    // getDataCategories();
  }, [filter]);

  const handleFilter = (e) => {
    const data = e.target.value;
    setFilter(data);
  };

  const handleSyncOpen = () => {
    setSyncLoading(true);

    syncGameUnipin().then((res) => {
      setDataGame(res.data.data)
      setSyncOpen(true);
      setSyncLoading(false)
    })
    .catch(error => {
      console.log(error);
      setSyncLoading(false)
    });
  }

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        className="my-4 d-flex items-center"
      >
        <Grid item xs={12} sm>
          <h1 className="text-black font-semibold text-25 my-auto">
            List Games
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: "flex-end" }}
        >
          <GeneralButton
            name={syncLoading ? "Loading..." : "Sync Game"}
            icon={<CloudSync />}
            variant="outlined"
            otherClass="py-3 px-5 mr-5"
            data={handleSyncOpen}
            disabled={syncLoading}
          />
          <Link to="/games/listGames/add">
            <ThemeProvider theme={theme}>
              <GeneralButton
                name="Add"
                icon={<AddIcon />}
                variant="contained"
              />
            </ThemeProvider>
          </Link>
        </Grid>
      </Grid>

      <SimpleCard title="">
        <div
          className="mt-2 mb-7 d-flex items-center "
          style={{ justifyContent: "flex-end" }}
        >
          <TextField
            size="small"
            variant="outlined"
            className="w-250"
            placeholder="Cari Games"
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                getData();
              }
            }}
            value={search}
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
          />

          <div className="ml-3">
            {/* <ListGamesFilter
              value={filter}
              label="Kategori"
              name="kategori"
              width="240px"
              handleChange={handleFilter}
              search
            /> */}
            <ListItemGameFilter
              value={filter}
              label="Kategori"
              name="kategori"
              width="240px"
              handleChange={handleFilter}
              search
            />
          </div>
        </div>
        <TableGamesListGames data={state.listGames} getData={getData} />
      </SimpleCard>

      <ModalSyncProduct
        handleClose={handleClose}
        open={syncOpen}
        handleSave={console.log("abc")}
        data={dataGame}
        count={0}
      />
    </div>
  );
};

export default GamesListGames;
