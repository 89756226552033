import { useEffect, useState } from "react";
import React from "react";
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Checkbox,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Aksieye from "./../../assets/components/icons/Aksieye.svg";
import { CheckBox } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { updateBulkStatusTransaction } from "../../redux/actions/Transaction/TransactionActions";
import Swal from "sweetalert2";

const RenderTable = ({
  data,
  state,
  setState,
  search,
  getData,
  customColumns,
  TotalColspan = 18,
  aksiSpan = 3,
  detailLink,
  id,
  checklist,
  checkedData = [],
}) => {
  const handleNumbering = () => {
    if (state.rowsPerPage === 20) {
      return state.page * 20;
    } else if (state.rowsPerPage === 40) {
      return state.page * 40;
    } else if (state.rowsPerPage === 100) {
      return state.page * 100;
    }
  };

  const handleCheckboxChange = (event) => {
    console.log(event.target);
    // checked or not
    const checked = event.target.checked;
    // get transaction_code
    const transaction_code = event.target.name;
    // console.log("target: ", target);
    if (checked === true) {
      setState((prevState) => ({
        ...prevState,
        data_checked: [...prevState.data_checked, transaction_code],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data_checked: prevState.data_checked.filter(
          (item) => item !== transaction_code
        ),
      }));
    }
    console.log("transaction_code: ", transaction_code);
  };

  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) => (
        <TableRow
          hover
          key={index}
          style={{
            borderTop: "1px #e6e5e5 solid",
            marginTop: "20px",
          }}
        >
          {checklist && (
            <>
              <TableCell
                className="text-14 pl-2 "
                align="center"
                style={{ color: "#0A0A0A" }}
                colSpan={1}
              >
                <Checkbox
                  color="primary"
                  onChange={handleCheckboxChange}
                  name={item.transaction_code}
                  checked={
                    checkedData.filter((dt) => dt === item.transaction_code)
                      .length > 0
                      ? true
                      : false
                  }
                />
              </TableCell>
            </>
          )}
          {/* <TableCell
            className="text-14 pl-2 "
            align="center"
            style={{ color: "#0A0A0A" }}
            colSpan={1}
          >
            {index + 1 + handleNumbering()}
          </TableCell> */}
          {customColumns?.map((column, index) => (
            <TableCell
              key={index}
              className="text-14 pl-2"
              // style={{ color: "#0A0A0A" }}
              style={
                column.type === "topup" && item[column.key] > 0
                  ? { color: "#51AF77" }
                  : column.type === "topup" && item[column.key] < 0
                  ? { color: "#D55454" }
                  : column.type === "status" && item[column.key] == "processing"
                  ? { color: "#1253FA" }
                  : (column.type === "status" &&
                      item[column.key] == "waiting") ||
                    (column.type === "status" && item[column.key] == "pending")
                  ? { color: "#DF8838" }
                  : column.type === "status" && item[column.key] == "success"
                  ? { color: "#51AF77" }
                  : { color: "#0A0A0A" }
              }
              colSpan={column.colSpan}
              align={column.align}
            >
              {column.type === "price" ||
              (column.type === "topup" && item[column.key])
                ? `Rp ${item[column.key]
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}`
                : column.type === "date" && item[column.key]
                ? new Date(item[column.key]).toLocaleDateString("en-US")
                : column.type !== "pembelian"
                ? item[column.key]
                : null}

              {/* Nomor Transaksi */}
              <Link to={`${detailLink}/${item[id]}`}>
                {column.type === "pembelian" && item[column.key]}
              </Link>
            </TableCell>
          ))}
          <TableCell align="center" colSpan={aksiSpan} className="pl-2">
            <Link to={`${detailLink}/${item[id]}`}>
              <img src={Aksieye} alt="eye" />
            </Link>
          </TableCell>
        </TableRow>
      ))
  ) : (
    <>
      <TableRow
        hover
        style={{
          borderTop: "1px #e6e5e5 solid",
          marginTop: "20px",
        }}
      >
        <TableCell colSpan={TotalColspan} align="center">
          Data kosong
        </TableCell>
      </TableRow>
    </>
  );
};

const TableCustom = ({
  search,
  data,
  getData,
  tableHeadItems,
  customColumns,
  TotalColspan,
  aksiSpan,
  detailLink,
  id,
  checklist,
}) => {
  // ==== START CHECKBOX ====
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 20,
    data_checked: [],
    status: "",
    allChecked: false,
  });

  const handleCheckboxChange = (event) => {
    // checked or not
    const checked = event.target.checked;
    if (checked === true) {
      var start = state.page;
      var end = (state.page + 1) * state.rowsPerPage;
      if (state.page > 0) {
        start = state.rowsPerPage + 1;
      }
      console.log(start, end);
      setState((prevState) => ({
        ...prevState,
        data_checked: data
          .slice(start, end)
          .map((item) => item.transaction_code),
        allChecked: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data_checked: [],
        allChecked: false,
      }));
    }
  };

  // useEffect(() => {
  //   console.log("state.data_checked: ", state.data_checked);
  // }, [state.data_checked]);

  // ==== END CHECKBOX ====

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
    setState((prevState) => ({
      ...prevState,
      data_checked: [],
      allChecked: false
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
      data_checked: [],
      allChecked: false
    });
  };
  const tableHead = tableHeadItems?.map((item, index) => (
    <TableCell key={index} align={item.align} colSpan={item.colSpan}>
      <div className="table-head-font">
        {" "}
        {item.name == "CL" ? (
          <Checkbox
            color="primary"
            onChange={handleCheckboxChange}
            checked={state.allChecked}
          />
        ) : (
          item.name
        )}
      </div>
    </TableCell>
  ));

  const handleChange = ({ target: { value, name } }) => {
    setState((pref) => ({
      ...pref,
      status: value,
    }));
  };

  const updateBulkStatus = () => {
    const params = {
      status: state.status,
      data: state.data_checked,
    };
    updateBulkStatusTransaction(params)
      .then((res) => {
        Swal.fire({
          title: "Berhasil",
          text: "Data berhasil diupdate",
          timer: 2000,
          icon: "success",
        });
        setState((prevState) => ({
          ...prevState,
          data_checked: [],
          allChecked: false
        }));
        getData();
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire({
          title: "gagal",
          text: "Data Gagal Diupdate",
          timer: 2000,
          icon: "error",
        });
      });
  };
  return (
    <div className="w-full overflow-auto bg-white izincuti-tabs-slide overflow-hidden">
      <Table
        style={{
          borderTop: "1px #e6e5e5 solid",
          marginTop: "20px",
        }}
      >
        <TableHead>
          <TableRow>{tableHead}</TableRow>
        </TableHead>

        <TableBody>
          <RenderTable
            data={data}
            state={state}
            setState={setState}
            getData={getData}
            search={search}
            customColumns={customColumns}
            aksiSpan={aksiSpan}
            detailLink={detailLink}
            id={id}
            checklist={checklist}
            checkedData={state.data_checked}
          />
        </TableBody>
      </Table>
      {checklist ? (
        <div className="d-flex justify-content-between align-content-center align-items-center">
          <div className="d-flex align-content-center align-items-center">
            <div className="d-flex align-content-center align-items-center">
              <CheckBox className="ml-5" htmlColor="#BABEC1" />
              <p className="p-0 m-0" style={{ color: "#BABEC1" }}>
                {state.data_checked.length}/{data.length} Data
              </p>
            </div>
            {state.data_checked.length > 0 ? (
              <div className="d-flex align-content-center align-items-center ml-5">
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      size="medium"
                      style={{ fontWeight: 700 }}
                    />
                  )}
                  onChange={handleChange}
                  value={state.status}
                  style={{ borderRadius: 5, maxHeight: 35 }}
                  size={"sm"}
                  className="bg-white p-0"
                  variant="outlined"
                  displayEmpty
                >
                  <MenuItem key={0} value={""} text={"Status"}>
                    Status
                  </MenuItem>
                  <MenuItem key={1} value={"success"} text={"Success"}>
                    Success
                  </MenuItem>
                  <MenuItem key={2} value={"processing"} text={"Processing"}>
                    Processing
                  </MenuItem>
                  <MenuItem key={3} value={"waiting"} text={"Waiting"}>
                    Waiting
                  </MenuItem>
                  <MenuItem key={4} value={"pending"} text={"Pending"}>
                    Pending
                  </MenuItem>
                  <MenuItem key={5} value={"cancel"} text={"Cancel"}>
                    Cancel
                  </MenuItem>
                </Select>
                {state.status !== "" ? (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className="text-white w-full text-capitalize pc-2 ml-3"
                    disableElevation
                    style={{
                      borderRadius: 5,
                    }}
                    onClick={updateBulkStatus}
                  >
                    Save
                  </Button>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <TablePagination
            className="px-16"
            rowsPerPageOptions={[20, 40, 100]}
            component="div"
            count={data?.length ? data?.length : 0}
            rowsPerPage={state.rowsPerPage}
            labelRowsPerPage={"From"}
            page={state.page}
            backIconButtonProps={{
              "aria-label": "Previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next page",
            }}
            backIconButtonText="Previous page"
            nextIconButtonText="Next page"
            onChangePage={handleChangePage}
            onChangeRowsPerPage={setRowsPerPage}
          />
        </div>
      ) : (
        <TablePagination
          className="px-16"
          rowsPerPageOptions={[20, 40, 100]}
          component="div"
          count={data?.length ? data?.length : 0}
          rowsPerPage={state.rowsPerPage}
          labelRowsPerPage={"From"}
          page={state.page}
          backIconButtonProps={{
            "aria-label": "Previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next page",
          }}
          backIconButtonText="Previous page"
          nextIconButtonText="Next page"
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPage}
        />
      )}
    </div>
  );
};

export default TableCustom;
