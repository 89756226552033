import { API, setAuthToken } from "../../../../config/API";
import { GET_API_DOCS, GET_DETAIL_API_DOCS, GET_DETAIL_API_DOCS_PARAMS, GET_API_DOCS_RESPONSES, GET_DETAIL_API_DOCS_RESPONSES, GET_API_DOCS_PARAMS } from "../../constant";

export const getApiDocs = (params) => {
    return (dispatch) => {
        const token = localStorage.getItem('jwt_token');
        setAuthToken(token);
        API.get(`/api_docs${params}`)
            .then((res) => {
                dispatch({
                    type: GET_API_DOCS,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_API_DOCS,
                    payload: [],
                });
            });
    };
};

export const getApiDocsParams = (id) => {
    return (dispatch) => {
        const token = localStorage.getItem('jwt_token');
        setAuthToken(token);
        API.get(`/api_docs/params/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_API_DOCS_PARAMS,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_API_DOCS_PARAMS,
                    payload: [],
                });
            });
    };
};

export const getApiDocsResponses = (id) => {
    return (dispatch) => {
        const token = localStorage.getItem('jwt_token');
        setAuthToken(token);
        API.get(`/api_docs/responses/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_API_DOCS_RESPONSES,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_API_DOCS_RESPONSES,
                    payload: [],
                });
            });
    };
};

export const getDetailApiDocs = (id) => {
    return (dispatch) => {
        const token = localStorage.getItem('jwt_token');
        setAuthToken(token);
        API.get(`/api_docs/detail/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_DETAIL_API_DOCS,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DETAIL_API_DOCS,
                    payload: [],
                });
            });
    };
};

export const getDetailApiDocsParams = (id) => {
    return (dispatch) => {
        const token = localStorage.getItem('jwt_token');
        setAuthToken(token);
        API.get(`/api_docs/param/detail/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_DETAIL_API_DOCS_PARAMS,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DETAIL_API_DOCS_PARAMS,
                    payload: [],
                });
            });
    };
};

export const getDetailApiDocsResponses = (id) => {
    return (dispatch) => {
        const token = localStorage.getItem('jwt_token');
        setAuthToken(token);
        API.get(`/api_docs/response/detail/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_DETAIL_API_DOCS_RESPONSES,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DETAIL_API_DOCS_RESPONSES,
                    payload: [],
                });
            });
    };
};

export const addApiDocs = (body) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('/api_docs/add', body);
};

export const addApiDocsParams = (body) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('/api_docs/param/add', body);
};

export const addApiDocsResponses = (body) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('/api_docs/response/add', body);
};

export const deleteApiDocs = (body) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('/api_docs/delete', body);
};

export const deleteApiDocsParam = (body) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('/api_docs/param/delete', body);
};
export const deleteApiDocsResponse = (body) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('/api_docs/response/delete', body);
};

export const editApiDocs = (params, body) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post(`/api_docs/edit/${params}`, body);
};

export const editApiDocsParam = (params, body) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post(`/api_docs/param/edit/${params}`, body);
};

export const editApiDocsResponse = (params, body) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post(`/api_docs/response/edit/${params}`, body);
};

export const updatePositionApiDocs = (body) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('/api_docs/update_position', body);
};

export const updatePositionApiDocsResponses = (body) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('/api_docs/responses/update_position', body);
};