import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Card, Icon } from "@material-ui/core";
import Swal from "sweetalert2";
import { editApiDocs } from "../../../redux/actions/Settings/ApiDocs";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const EditApiDocumentation = () => {
    const history = useHistory();
    const location = useLocation();
    const locationState = location.state;

    const [state, setState] = useState({
        route_name: locationState.route_name || "",
        method: locationState.method || "",
        url: locationState.url || ""
    });
    const id = locationState.api_docs_code || ""

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const checkUrl = (url) => {
        const urlRegex = /^(http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    };

    const handleSubmit = () => {
        const { url } = state;

        if (!checkUrl(url)) {
            Swal.fire({
                icon: "error",
                title: "URL tidak valid",
                text: "Harap masukkan URL yang valid!",
                showConfirmButton: true,
                timer: 2000,
            });
            return;
        }

        try {
            Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Dokumentasi API berhasil diperbarui!",
                showConfirmButton: true,
                timer: 2000,
            });

            editApiDocs(id, state);

            setState({
                route_name: "",
                method: "",
                url: ""
            })

            return history.push("/settings/apidocs")

        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: "error",
                title: "Gagal",
                text: "Gagal menyimpan Dokumentasi API!",
                showConfirmButton: true,
                timer: 2000,
            });
        }
    };

    const handleBack = () => {
        history.push("/settings/apidocs");
    }
    console.log('itemm', locationState)
    return (
        <div className="m-sm-30">
            {/* Head */}
            <Grid
                container
                spacing={1}
                className="d-flex items-center"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={12} sm>
                    <h2 className="fw-600 text-25 text-black">Edit Dokumentasi API</h2>
                </Grid>
                <Grid item xs={12} lg={3} sm className="d-flex justify-end">
                    <Grid container spacing={2}>
                        <Grid item lg={6} xl={6}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className="px-5 py-2 br-2 text-primary fw-500"
                                onClick={handleBack}
                            >
                                <span className="">Kembali</span>
                            </Button>
                        </Grid>
                        <Grid item lg={6} xl={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="px-5 py-2 br-2 text-white fw-500"
                                onClick={handleSubmit}
                            >
                                <span className="">Simpan</span>
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <div className="my-8">
                <Card className="py-10 px-5">
                    {/* Menu */}
                    <div className="px-10 mx-10">
                        <Grid
                            container
                            className="mt-3"
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-end"
                        >
                            <Grid item xs={12} sm={12}>
                                <h3 className="mb-5 fw-500 text-15 text-black">Nama Route</h3>
                                <TextField
                                    InputProps={{
                                        style: {
                                            borderRadius: 5,
                                            minHeight: 35,
                                        },
                                    }}
                                    size="small"
                                    name="route_name"
                                    className={`w-full`}
                                    placeholder="contoh: List Product"
                                    variant="outlined"
                                    value={
                                        state.hasOwnProperty(`route_name`)
                                            ? state[`route_name`]
                                            : ""
                                    }
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <h3 className="mb-5 fw-500 text-15 text-black">URL Endpoint</h3>
                                <TextField
                                    InputProps={{
                                        style: {
                                            borderRadius: 5,
                                            minHeight: 35,
                                        },
                                    }}
                                    size="small"
                                    name="url"
                                    className={`w-full`}
                                    placeholder="contoh: https://api.example.com/api/list-product"
                                    variant="outlined"
                                    value={
                                        state.hasOwnProperty(`url`)
                                            ? state[`url`]
                                            : ""
                                    }
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <h3 className="mb-5 fw-500 text-15 text-black">Method</h3>
                                <TextField
                                    InputProps={{
                                        style: {
                                            borderRadius: 5,
                                            minHeight: 35,
                                        },
                                    }}
                                    size="small"
                                    name="method"
                                    className={`w-full`}
                                    placeholder="POST/GET/PUT/DELETE"
                                    variant="outlined"
                                    value={
                                        state.hasOwnProperty(`method`)
                                            ? state[`method`]
                                            : ""
                                    }
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default EditApiDocumentation;
