import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  Card,
  Dialog,
  TextField,
} from "@material-ui/core";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import SimpleCard from "../../assets/components/cards/SimpleCard";
import React, { Component, Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Swal from "sweetalert2";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  getDetailTopUp,
  getDetailUserTopup,
  updateUserSaldo,
} from "../../redux/actions/Transaction/TransactionActions";
import { formatRupiah } from "../../../utlis/formatRupiah";
import { parse } from "date-fns";
import { useLayoutEffect } from "react";
import { TableDetailTopup } from "../../components";
import SelectWithTextAndValue from "../../components/select/SelectWithTextAndValue";
import { capitalize } from "../../../utlis/capitalize";

const DetailTopUp = ({ getDetailUserTopup, detailUserTopup, userSaldo }) => {
  const { id } = useParams();
  // console.log('test')

  const useStyles = makeStyles({
    dialog: {
      // height: 'fit-content',
      scrollbarColor: "transparent",
      scrollbarWidth: "0px",
      // minWidth: "800px",
      // maxWidth: "1200px,",
      // overflow: 'hidden',
    },
    backDrop: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  });
  const classes = useStyles();

  const [state, setState] = React.useState({
    users_code: "",
    action: "",
    nominal: 0,
    new_saldo: 0,
    keterangan: "",
  });

  useEffect(() => {
    if (
      state.action === "TAMBAH" &&
      state.nominal !== 0 &&
      state.nominal !== ""
    ) {
      setState({
        ...state,
        new_saldo:
          parseInt(detailUserTopup.data_user?.users_balance) +
          parseInt(state.nominal),
      });
    } else if (
      state.action === "KURANG" &&
      state.nominal !== 0 &&
      state.nominal !== ""
    ) {
      setState({
        ...state,
        new_saldo:
          parseInt(detailUserTopup.data_user?.users_balance) -
          parseInt(state.nominal),
      });
    }
    // console.log(state);
  }, [state.action, state.nominal]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      action: "",
      nominal: "",
      new_saldo: 0,
      // keterangan: "",
    }));
    setOpen(false);
  };

  const handleTombolTambahSaldo = () => {
    handleClickOpen();
    setState((prev) => ({
      ...prev,
      action: "TAMBAH",
    }));
  };

  const handleTombolKurangSaldo = () => {
    handleClickOpen();
    setState((prev) => ({
      ...prev,
      action: "KURANG",
    }));
  };

  const handleSubmit = () => {
    let obj = {
      users_code: state.users_code,
      action: state.action,
      nominal: state.nominal,
      keterangan: state.keterangan,
    };
    try {
      if (
        state.users_code == "" ||
        state.action == "" ||
        state.nominal == "" ||
        state.keterangan == ""
      ) {
        Swal.fire("Gagal!", "Harap isi semua inputan !", "warning");
        return;
      } else if (state.nominal <= 0) {
        Swal.fire(
          "Gagal!",
          "Nominal tidak boleh 0 atau lebih rendah!",
          "warning"
        );
        return;
      }

      console.log("obj", obj);
      updateUserSaldo(obj)
        .then((res) => {
          console.log(res);
          setState((prev) => ({
            action: "",
            nominal: "",
            new_saldo: 0,
            keterangan: "",
          }));
          getDataDetailUserTopUp(state.users_code);
          setOpen(false);
          Swal.fire("Berhasil!", "Saldo user berhasil ditambahkan!", "success");
        })
        .catch((err) => {
          console.log(err);
          Swal.fire("Gagal!", "System Under Maintenance !", "error");
        });
    } catch (e) {
      console.log(e);
      Swal.fire("Gagal!", "Saldo user gagal diperbarui", "error");
    }
  };

  const getData = () => {
    getDataDetailUserTopUp(id);
  };

  const getDataDetailUserTopUp = (code) => {
    getDetailUserTopup(code);
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      users_code: detailUserTopup.data_user?.users_code,
    }));
  }, [detailUserTopup]);

  useEffect(() => {
    getData();
  }, [id]);
  const history = useHistory();

  const tableHeadItems = [
    { name: "No", align: "center", colSpan: 1 },
    { name: "Nominal", align: "center", colSpan: 2 },
    { name: "Waktu Transaksi", align: "center", colSpan: 3 },
    { name: "Keterangan", align: "center", colSpan: 4 },
    { name: "Metode Pembayaran", align: "center", colSpan: 4 },
    { name: "Status", align: "center", colSpan: 2 },
  ];

  const tableBodyItems = [
    { key: "total_amount", align: "center", colSpan: 2, type: "topup" },
    { key: "created_at", align: "center", colSpan: 3, type: "date" },
    { key: "game_transaction_message", align: "center", colSpan: 4 },
    { key: "pm_title", align: "center", colSpan: 4 },
    { key: "status", align: "center", colSpan: 2, type: "status" },
  ];

  const { users_type } = useSelector(({ user }) => user);

  return detailUserTopup ? (
    <div className="m-sm-30">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <h1 className="mb-20px header-detail">Riwayat Top Up </h1>
        <div className="back-button">
          <Button onClick={() => history.push("/transaction")}>Back</Button>
        </div>
      </div>
      <SimpleCard loading={false} currency="" saldo="">
        <div className="simple-card-content">
          <Fragment>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h5>Nama Pengguna</h5>
                <div>{detailUserTopup.data_user?.name}</div>
              </Grid>

              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>User Code</h5>
                <div>{detailUserTopup.data_user?.users_code}</div>
                {/* <div>{formatRupiah(parseInt(detailTopup.total_amount))}</div> */}
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Email</h5>
                <div>{detailUserTopup.data_user?.email}</div>
              </Grid>

              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>Balance</h5>
                <div>
                  {formatRupiah(
                    parseInt(detailUserTopup.data_user?.users_balance)
                  )}
                </div>
              </Grid>
            </Grid>
          </Fragment>
          {users_type == "ADM" && (
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <div className="back-button">
                  <Button onClick={handleTombolKurangSaldo}>
                    <RemoveIcon /> Kurangi Saldo
                  </Button>
                </div>
              </Grid>
              <Grid item>
                <div className="back-button">
                  <Button onClick={handleTombolTambahSaldo}>
                    <AddIcon /> Tambah Saldo
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </SimpleCard>
      {open && (
        <>
          {/* ======== START ADD MODAL ======== */}
          <Dialog
            classes={{
              paper: classes.dialog,
            }}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            open={open}
            onClose={handleClose}
            scroll="body"
          >
            <Card className="p-5 px-10">
              <Grid containerspacing={4}>
                <Grid
                  item
                  xs={12}
                  className="text-17 text-black fw-600 text-center"
                >
                  {state.action == "KURANG"
                    ? capitalize(state.action) + "i"
                    : capitalize(state.action)}{" "}
                  Saldo
                </Grid>
              </Grid>
              <Grid container spacing={4} className="mt-4">
                <Grid item xs={12} md={12}>
                  <h1 className="mt-5 mb-5 text-14 text-slate-100">
                    Saldo Saat ini
                  </h1>
                  <h1 className="mt-5 text-28 text-black">
                    {formatRupiah(
                      parseInt(detailUserTopup.data_user?.users_balance)
                    )}
                  </h1>
                </Grid>
                <Grid item xs={12} md={12}>
                  <h1 className="mb-5 fw-500 text-13 text-black">
                    Nominal{" "}
                    {state.action == "KURANG"
                      ? capitalize(state.action) + "i"
                      : capitalize(state.action)}{" "}
                    Saldo
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    name="pm_title"
                    type="number"
                    className={`w-full`}
                    onChange={(e) =>
                      setState({ ...state, nominal: e.target.value })
                    }
                    placeholder="Nominal"
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: 5,
                        minHeight: 46,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <h1 className="mb-5 fw-500 text-13 text-black">
                    Jumlah Saldo
                  </h1>
                  <TextField
                    size="small"
                    name="new_saldo"
                    value={formatRupiah(parseInt(state.new_saldo))}
                    disabled={true}
                    className={`w-full`}
                    placeholder="Saldo"
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: 5,
                        minHeight: 46,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm>
                  <h1 className="mb-5 fw-500 text-13 text-black">Keterangan</h1>
                  <TextField
                    required={true}
                    size="small"
                    multiline
                    rows={4}
                    InputProps={{
                      style: {
                        borderRadius: 5,
                        minHeight: 46,
                      },
                    }}
                    className="w-full mt-2"
                    value={state.keterangan}
                    name="keterangan"
                    placeholder="Keterangan"
                    variant="outlined"
                    onChange={(e) =>
                      setState({ ...state, keterangan: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
                className="my-8"
              >
                <div className="d-flex items-center justify-start gap-11">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="w-140 py-2 px-30 text-14 border-radius-4 text-center fw-500"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="w-140 border-radius-4 py-2 px-30 text-14 text-center fw-500 text-white"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Card>
          </Dialog>
          {/* ======== END ADD MODAL ======== */}
        </>
      )}

      <div className="mt-5">
        <SimpleCard loading={false} currency="" saldo="">
          <TableDetailTopup
            tableHeadItems={tableHeadItems}
            data={detailUserTopup?.data_topup}
            customColumns={tableBodyItems}
            aksiSpan={2}
            id={"transaction_code"}
            getData={() => getData()}
          />
        </SimpleCard>
      </div>
    </div>
  ) : (
    <div>wait</div>
  );
};

const mapStateToProps = (state) => {
  return {
    // detailTopup: state.transaction.detailTopup,
    detailUserTopup: state.transaction.detailUserTopup,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getDetailTopUp: (id) => dispatch(getDetailTopUp(id)),
    getDetailUserTopup: (params) => dispatch(getDetailUserTopup(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTopUp);
